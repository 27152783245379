import { NumericFormat } from "react-number-format";

type Props = {
  type?: "text" | "password";
  placeholder?: string;
} & Record<string, any>;

export default function PercentInput({
  type = "text",
  placeholder,
  value,
  onChange,
  ...otherProps
}: Props) {
  return (
    <NumericFormat
      placeholder={placeholder}
      decimalScale={0}
      decimalSeparator=","
      value={value}
      onValueChange={({ value }) => onChange(value)}
      suffix=" %"
      thousandSeparator={false}
      className="input input-bordered bg-white w-full"
      {...otherProps}
    />
  );
}
