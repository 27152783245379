import { ProductCategory } from "../types/entities/product-category";
import { CrudService } from "./shared/crud-service";

class BillService extends CrudService<ProductCategory> {
  constructor() {
    super("/protected/bill");
  }
}

export const billService = new BillService();
