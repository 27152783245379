/* eslint-disable @typescript-eslint/no-array-constructor */

type Props = {
  visible: boolean;
  size?: number;
  children: any;
};

export default function VisibilityWrapper({
  size = 4,
  visible,
  children,
}: Props) {
  const hidedText = new Array(size).fill("*").join("");

  return visible ? children : <span> {hidedText}</span>;
}
