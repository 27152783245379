import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Resolver, useForm, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { boolean, date, number, object, string } from "yup";
import Button from "../../components/buttons/Button";
import SelectInput from "../../components/inputs/SelectInput";
import TextInput from "../../components/inputs/TextInput";
import FullLoading from "../../components/others/FullLoading";
import { billService } from "../../services/bill-service";
import { Bill, BillType } from "../../types/entities/bill";
import { BillGroup } from "../../types/entities/bill-group";
import FormFooter from "../shared/FormFooter";
import FormSection from "../shared/FormSection";
import InputContainer from "../shared/InputContainer/InputContainer";
import { billGroupService } from "../../services/bill-group-service";
import TextAreaInput from "../../components/inputs/TextAreaInput";
import CurrencyInput from "../../components/inputs/CurrencyInput";
import ToggleInput from "../../components/inputs/ToggleInput";
import DateInput from "../../components/inputs/DateInput";

type FormValues = Partial<Bill>;

type Props = {
  onSubmit: (values: FormValues) => Promise<void>;
};

const validationSchema = object({
  name: string().required("Campo obrigatório"),
  description: string().optional(),
  type: string().oneOf(Object.values(BillType)).required("Campo obrigatório"),
  groupId: string().required("Campo obrigatório"),
  value: number().min(0).required("Campo obrigatório"),
  effective: boolean().required(),
  effectiveAt: date().required("Campo obrigatório"),
});

const billTypeOptions = [
  {
    label: "Despesa",
    value: BillType.Cost,
  },
  {
    label: "Receita",
    value: BillType.Income,
  },
];

export default function BillForm({ onSubmit }: Props) {
  const [loading, setLoading] = useState(false);

  const params = useParams();

  const form = useForm<FormValues>({
    resolver: yupResolver(validationSchema) as Resolver<FormValues>,
    mode: "onTouched",
    defaultValues: {
      name: "",
      description: "",
      value: 0,
      type: BillType.Cost,
      effective: true,
      effectiveAt: new Date(),
    },
  });

  const loadInitialValues = async () => {
    setLoading(true);
    try {
      const id = params.id;
      if (id) {
        const initialValues = await billService.getById(id);

        delete initialValues.id;
        delete initialValues.createdAt;
        delete initialValues.updatedAt;
        delete initialValues.deletedAt;

        form.reset(initialValues);
      }
    } catch (error) {
      toast.error("Erro ao carregar registro");
    } finally {
      setLoading(false);
    }
  };

  const submit = async (values: Partial<BillGroup>) => {
    try {
      const id = params.id;
      if (id) {
        await billService.update(id, values);
        toast.success("Registro atualizado com sucesso");
      } else {
        await billService.create(values);
        toast.success("Registro criado com sucesso");
      }
      onSubmit(values);
    } catch (error) {
      toast.error("Erro ao carregar registro");
    }
  };

  useEffect(() => {
    loadInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const [type] = useWatch({ name: ["type"], control: form.control });

  return (
    <form className="w-full relative" onSubmit={form.handleSubmit(submit)}>
      <div className="flex flex-col justify-center w-full">
        <FormSection>Geral</FormSection>
        <div className="grid grid-col-1 w-full">
          <div className="grid grid-cols-4 gap-4">
            <div className="col-span-2">
              <InputContainer
                form={form}
                name="name"
                Component={TextInput}
                label="Nome"
              />
            </div>
            <div>
              <InputContainer
                form={form}
                name="type"
                Component={SelectInput}
                label="Tipo"
                options={billTypeOptions}
              />
            </div>
            <div>
              <InputContainer
                form={form}
                name="groupId"
                Component={SelectInput}
                label="Grupo"
                revalidate={[type]}
                optionsFetcher={(query: any) =>
                  billGroupService.get({ match: { ...query.match, type } })
                }
                itemFetcher={(id: string) => billGroupService.getById(id)}
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <InputContainer
                form={form}
                name="value"
                Component={CurrencyInput}
                label="Valor"
              />
            </div>
            <div className="flex gap-4">
              <div>
                <InputContainer
                  form={form}
                  name="effective"
                  Component={ToggleInput}
                  label="Pago?"
                />
              </div>
              <div className="flex-grow">
                <InputContainer
                  form={form}
                  name="effectiveAt"
                  Component={DateInput}
                  label="Data de pagamento"
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4">
            <div>
              <InputContainer
                form={form}
                name="description"
                Component={TextAreaInput}
                label="Descrição"
              />
            </div>
          </div>
        </div>
        <FormFooter>
          <Button
            type="submit"
            color="success"
            loading={form.formState.isSubmitting}
            disabled={!form.formState.isValid}
          >
            Salvar
          </Button>
        </FormFooter>
      </div>
      {loading && <FullLoading />}
    </form>
  );
}
