import { WithId } from "./shared/with-id";
import { WithTimestamps } from "./shared/with-timestamps";

export type SalesPeriodTax = {
  taxId: string;
  taxName: string;
  value?: number;
};

export enum SalesPeriodStatus {
  Open = "open",
  Finished = "finished",
}

export type SalesPeriod = {
  status: SalesPeriodStatus;
  companyId: string;
  taxes: SalesPeriodTax[];
  name: string;
  description?: string;
  value: number;
  initialMoney: number;
  startedBy: string;
  finishedBy?: string;
  startedAt: Date;
  finishedAt?: Date;
} & WithId &
  WithTimestamps;
