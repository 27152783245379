import SellSOLogo from "../../assets/logo.svg";
import SalesPeriodWidget from "./SalesPeriodWidget";
import SidebarList from "./SidebarList/SidebarList";

export default function Sidebar() {
  return (
    <div className="h-full w-[285px] min-w-[285px] shadow flex flex-col">
      {/* logo container */}
      <div className="w-full flex justify-center bg-primary h-[85px]">
        <img src={SellSOLogo} alt="sell so logo" width="150px"></img>
      </div>
      <SalesPeriodWidget />
      <SidebarList />
      {/* company selector */}
      {/* <div className="w-full flex justify-center p-4 border-b border-b-primary_light">
        <CompanySelector />
      </div> */}
      {/* sale period container */}

      {/* sidebar list */}
    </div>
  );
}
