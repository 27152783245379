import { OrderLocation } from "../types/entities/order-location";
import { axiosInstance } from "./shared/axios-instance";
import { CrudService } from "./shared/crud-service";

class OrderLocationService extends CrudService<OrderLocation> {
  constructor() {
    super("/protected/order-location");
  }

  async getAvailableOrderLocationsBySalesPeriod(salesPeriodId: string) {
    return axiosInstance.get(
      `/protected/sales-period/${salesPeriodId}/order-location/available`
    );
  }
}

export const orderLocationService = new OrderLocationService();
