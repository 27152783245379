import { classNames } from "primereact/utils";

type Props = {
  icon: string;
  title: string;
  indicator?: string | number;
  text: string;
  colorClassName?: string;
};

export default function IndicatorCard({
  icon,
  title,
  indicator,
  text,
  colorClassName = "text-primary",
}: Props) {
  return (
    <div className="shadow rounded bg-white border p-4 text-gray-600">
      <div className="w-full flex justify-between items-center h-full">
        <div className="flex flex-col">
          <span className={classNames("font-semibold")}>
            {title}
          </span>
          <span className={classNames("font-semibold text-lg mt-1", colorClassName)}>
            {indicator}
          </span>
          <div className="mt-1">
            <span className={classNames("text-sm")}>{text}</span>
          </div>
        </div>
        <i className={`${icon} text-5xl ${colorClassName}`}> </i>
      </div>
    </div>
  );
}
