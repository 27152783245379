import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Resolver, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { object, string } from "yup";
import Button from "../../components/buttons/Button";
import TextInput from "../../components/inputs/TextInput";
import FullLoading from "../../components/others/FullLoading";
import { salesPointService } from "../../services/sales-point-service";
import { ProductCategory } from "../../types/entities/product-category";
import { SalesPoint } from "../../types/entities/sales-point";
import InputContainer from "../shared/InputContainer/InputContainer";
import FormSection from "../shared/FormSection";
import FormFooter from "../shared/FormFooter";

type FormValues = Partial<SalesPoint>;

type Props = {
  onSubmit: (values: FormValues) => Promise<void>;
};

const validationSchema = object({
  name: string().required("Campo obrigatório"),
});

export default function SalesPointForm({ onSubmit }: Props) {
  const [loading, setLoading] = useState(false);

  const params = useParams();

  const form = useForm<FormValues>({
    resolver: yupResolver(validationSchema) as Resolver<FormValues>,
    mode: "onTouched",
    defaultValues: {
      name: "",
    },
  });

  const loadInitialValues = async () => {
    setLoading(true);
    try {
      const id = params.id;
      if (id) {
        const initialValues = await salesPointService.getById(id);

        delete initialValues.id;
        delete initialValues.createdAt;
        delete initialValues.updatedAt;
        delete initialValues.deletedAt;

        form.reset(initialValues);
      }
    } catch (error) {
      toast.error("Erro ao carregar registro");
    } finally {
      setLoading(false);
    }
  };

  const submit = async (values: Partial<ProductCategory>) => {
    try {
      const id = params.id;
      if (id) {
        await salesPointService.update(id, values);
        toast.success("Registro atualizado com sucesso");
      } else {
        await salesPointService.create(values);
        toast.success("Registro criado com sucesso");
      }
      onSubmit(values);
    } catch (error) {
      toast.error("Erro ao carregar registro");
    }
  };

  useEffect(() => {
    loadInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  return (
    <form className="w-full relative" onSubmit={form.handleSubmit(submit)}>
      <div className="flex flex-col justify-center w-full">
        <FormSection>Geral</FormSection>
        <div className="grid grid-col-1 w-full">
          <div className="grid grid-cols-1 gap-4">
            <div>
              <InputContainer
                form={form}
                name="name"
                Component={TextInput}
                label="Nome"
              />
            </div>
          </div>
        </div>
        <FormFooter>
          <Button
            type="submit"
            color="success"
            loading={form.formState.isSubmitting}
            disabled={!form.formState.isValid}
          >
            Salvar
          </Button>
        </FormFooter>
      </div>
      {loading && <FullLoading />}
    </form>
  );
}
