import { Controller, UseFormReturn } from "react-hook-form";

type Props = {
  form: UseFormReturn;
  name: string;
  label?: string;
  Component: any;
} & Record<string, any>;

export default function InputContainer({
  form,
  name,
  label,
  Component,
  ...componentProps
}: Props) {
  return (
    <Controller
      control={form.control}
      name={name}
      render={({ field: { ref, ...field }, fieldState }) => (
        <div className="w-full mb-3 flex flex-col">
          {label && <span className="mb-1">{label}</span>}
          <Component {...field} {...componentProps} />
          {fieldState.error && (
            <span className="text-red-400 text-sm mt-1">
              {fieldState.error.message}
            </span>
          )}
        </div>
      )}
    />
  );
}
