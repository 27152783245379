import { debounce } from "lodash";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ReactElement, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { PaginatedResponse } from "../../services/shared/crud-service";
import TextInput from "../inputs/TextInput";
import FullLoading from "../others/FullLoading";
import {
  formatTablePagination,
  formatTableSearch,
  formatTableSort,
} from "./table-helpers";

export type TableColumn = {
  field: string;
  label: string;
  formatter?: (value: any) => string;
};

export type TableSortInfo = {
  field: string;
  order: 0 | 1 | -1 | null | undefined;
};

export type TablePaginationInfo = {
  first: number;
  page?: number;
  rows: number;
};

type Props = {
  columns: TableColumn[];
  dataFetch: (query: any) => Promise<PaginatedResponse>;
  actionsColumn?: (row: any, reloadData: () => Promise<void>) => ReactElement;
};

export default function Table({ columns, actionsColumn, dataFetch }: Props) {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<PaginatedResponse>();
  const [search, setSearch] = useState<string>("");
  const [sort, setSort] = useState<TableSortInfo>();
  const [pagination, setPagination] = useState<TablePaginationInfo>({
    first: 0,
    rows: 10,
    page: 0,
  });

  const fetchData = async (query: any = {}) => {
    setLoading(true);
    try {
      const data = await dataFetch(query);
      setData(data);
    } catch (error) {
      toast.error("Erro ao carregar dados");
    } finally {
      setLoading(false);
    }
  };

  const debouncedFechData = useMemo(() => {
    return debounce(fetchData, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchData({
      match: formatTableSearch(search, "name"),
      sort: formatTableSort(sort),
      pagination: formatTablePagination(pagination),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, pagination]);

  useEffect(() => {
    debouncedFechData({
      match: formatTableSearch(search, "name"),
      sort: formatTableSort(sort),
      pagination: formatTablePagination(pagination),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFechData, search]);

  return (
    <div className="w-full relative mb-4">
      <div className="flex justify-end mb-4">
        <div className="w-1/3">
          <TextInput
            placeholder="Pesquisar..."
            value={search}
            onChange={(event: any) => setSearch(event.target.value)}
          />
        </div>
      </div>
      <div className="rounded overflow-hidden">
        <DataTable
          value={data?.items}
          stripedRows
          paginator
          lazy
          emptyMessage="Não foram encontrados resultados..."
          first={pagination.first}
          rows={pagination.rows}
          totalRecords={data?.count}
          onPage={(page) =>
            setPagination({
              first: page.first,
              page: page.page,
              rows: page.rows,
            })
          }
          rowsPerPageOptions={[5, 10, 25, 50]}
          sortField={sort?.field}
          sortOrder={sort?.order}
          onSort={(event) =>
            setSort({
              field: event.sortField,
              order: event.sortOrder,
            })
          }
        >
          {columns.map((colum) => (
            <Column
              key={colum.field}
              field={colum.field}
              header={colum.label}
              body={(row) =>
                colum.formatter
                  ? colum.formatter(row[colum.field])
                  : row[colum.field]
              }
              sortable
            />
          ))}
          {actionsColumn && (
            <Column
              key={"__actions"}
              field="__actions"
              header="Ações"
              align="center"
              body={(row) => actionsColumn(row, fetchData)}
            />
          )}
        </DataTable>
      </div>
      {loading && <FullLoading />}
    </div>
  );
}
