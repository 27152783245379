import { WithId } from "./shared/with-id";
import { WithTimestamps } from "./shared/with-timestamps";

export enum SaleType {
  Product = "product",
  Tax = "tax",
}

export type TaxSale = {
  taxId: string;
  type: SaleType.Tax;
};

export type ProductSaleComposition = {
  productId: string;
  percent: number;
};

export type ProductSale = {
  productId: string;
  composition?: ProductSaleComposition[];
  type: SaleType.Product;
};

export type Sale = {
  companyId: string;
  salesPeriodId: string;
  salesGroupId: string;
  salesPointId?: string;
  type: SaleType;
  name: string;
  description: string;
  quantity: number;
  value: number;
  cost: number;
  totalValue: number;
  exemptTaxes?: boolean
} & WithId &
  WithTimestamps &
  (ProductSale | TaxSale);
