
type Props = {
  children: string;
};

export default function FormSection({ children }: Props) {
  return (
    <div className="border-b border-dashed mb-3 pb-3">
      <h2 className="text-gray-600 text-lg">{children}</h2>
    </div>
  );
}
