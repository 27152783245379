import { AuthInfo } from "../contexts/auth/AuthContext";
import { axiosInstance } from "./shared/axios-instance";

export type loginRequest = {
  email: string;
  password: string;
};

export type LoginResponse = {
  name: string;
  email: string;
  id: string;
  token: string;
};

export type RegisterRequest = {
  name: string;
  email: string;
  password: string;
};

export type VerifyRequest = {
  token: string;
};

class UserService {
  async login(request: loginRequest): Promise<LoginResponse> {
    return await axiosInstance.post("/login", request);
  }
  async loadAuthorization(): Promise<AuthInfo> {
    return await axiosInstance.get("/protected/auth");
  }
  async register(request: RegisterRequest): Promise<unknown> {
    return await axiosInstance.post("/create-user", request);
  }
  async verify(request: VerifyRequest): Promise<AuthInfo> {
    return await axiosInstance.post("/verify-user", request);
  }
}

export const userService = new UserService();
