import { WithId } from "./shared/with-id";
import { WithTimestamps } from "./shared/with-timestamps";

export enum SalesGroupType {
  Local = "local",
  Delivery = "delivery",
}

export enum SalesGroupStatus {
  Open = "open",
  Finished = "finished",
}

export type SalesGroup = {
  companyId: string;
  salesPeriodId: string;
  salesPointId?: string;
  clientId?: string;
  orderLocationIds?: string[];
  name: string;
  type: SalesGroupType;
  status: SalesGroupStatus;
  description?: string;
  value: number;
  startedBy: string;
  finishedBy?: string;
  startedAt: Date;
  finishedAt?: Date;
} & WithId &
  WithTimestamps;
