import { axiosInstance } from "./axios-instance";

export type PaginatedResponse<DataType = any> = {
  limit: number;
  skipped: number;
  count: number;
  items: DataType[];
};

export type OkResponse = {
  ok: boolean;
};

export class CrudService<T> {
  constructor(protected baseUrl: string) {}

  async create(data: Partial<T>): Promise<T> {
    return await axiosInstance.post(this.baseUrl, data);
  }

  async update(id: string, data: Partial<T>): Promise<OkResponse> {
    return await axiosInstance.patch(this.baseUrl + `/${id}`, data);
  }

  async get(query: any): Promise<PaginatedResponse<T>> {
    return await axiosInstance.get(this.baseUrl, {
      params: {
        _query: JSON.stringify(query),
      },
    });
  }

  async getById(id: string): Promise<T> {
    return await axiosInstance.get(this.baseUrl + `/${id}`);
  }

  async delete(id: string): Promise<OkResponse> {
    return await axiosInstance.delete(this.baseUrl + `/${id}`);
  }
}
