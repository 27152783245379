import React, { useContext } from "react";
import { TokenPayload } from "../../types/definitions/TokenPayload";
import { Company } from "../../types/entities/company";

export type AuthInfo = TokenPayload & {
  companies: Company[];
};
export type AuthContext = {
  authenticated: boolean;
  auth?: AuthInfo;
  setAuth: (auth: AuthInfo) => void;
  loadAuth: () => Promise<void>;
  logout: () => void;
  company?: Company;
  setCompany: (company: Company) => void;
};

export const authContext = React.createContext<AuthContext>({
  authenticated: false,
} as AuthContext);

export const useAuthContext = () => {
  return useContext(authContext);
};
