import { yupResolver } from "@hookform/resolvers/yup";
import { Resolver, useForm } from "react-hook-form";
import { object, string } from "yup";
import Button from "../../components/buttons/Button";
import TextInput from "../../components/inputs/TextInput";
import { Company } from "../../types/entities/company";
import InputContainer from "../shared/InputContainer/InputContainer";

type FormValues = Partial<Company>;

type Props = {
  onSubmit: (values: FormValues) => Promise<void>;
};

const validationSchema = object({
  name: string().required("Campo obrigatório"),
  description: string().optional(),
});

export default function CompanyForm({ onSubmit }: Props) {
  const form = useForm<FormValues>({
    resolver: yupResolver(validationSchema) as Resolver<FormValues>,
    mode: "onTouched",
    defaultValues: {
      name: "",
      description: "",
    },
  });

  return (
    <form className="w-full" onSubmit={form.handleSubmit(onSubmit)}>
      <div className="flex flex-col items-center justify-center w-full">
        <div className="w-full">
          <InputContainer
            form={form}
            name="name"
            Component={TextInput}
            label="Nome"
          />
        </div>
        <div className="w-full">
          <InputContainer
            form={form}
            name="description"
            Component={TextInput}
            label="Descrição"
          />
        </div>
        <div className="w-full flex justify-center mt-4">
          <Button
            type="submit"
            loading={form.formState.isSubmitting}
            disabled={!form.formState.isValid}
          >
            Criar organização
          </Button>
        </div>
      </div>
    </form>
  );
}
