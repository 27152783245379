import SidebarOption from "./SidebarOption";
import { sidebarConfig } from "./sidebar-list-config";

export default function SidebarList() {
  return (
    <ul className="">
      {sidebarConfig.map((option) => (
        <SidebarOption key={option.title} {...option} />
      ))}
    </ul>
  );
}
