import { SalesPoint } from "../types/entities/sales-point";
import { CrudService } from "./shared/crud-service";

class SalesPointService extends CrudService<SalesPoint> {
  constructor() {
    super("/protected/sales-point");
  }
}

export const salesPointService = new SalesPointService();
