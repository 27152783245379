import { useNavigate } from "react-router-dom";
import ProductCategoryForm from "../../../forms/product/ProductCategoryForm";
import CommonPage from "../../shared/CommonPage";

export default function ProductCategoryFormPage() {
  const navigate = useNavigate();
  return (
    <CommonPage title="Categorias de produtos" description="Formulário de categorias de produtos">
      <div className="shadow-md p-4 w-full rounded-md  bg-white">
        <ProductCategoryForm onSubmit={async () => navigate(-1)} />
      </div>
    </CommonPage>
  );
}
