export type SidebarOptionConfig = {
  title: string;
  icon?: string;
  link?: string;
  options?: SidebarOptionConfig[];
};

export const sidebarConfig: SidebarOptionConfig[] = [
  {
    title: "Dashboard",
    icon: "ri-dashboard-line",
    link: "/home",
  },
  {
    title: "Produtos",
    icon: "ri-shopping-cart-line",
    link: "/products",
    options: [
      {
        title: "Produtos",
        link: "/products/products",
      },
      {
        title: "Categorias",
        link: "/products/categories",
      },
      {
        title: "Fornecedores",
        link: "/products/providers",
      },
    ],
  },
  {
    title: "Financeiro",
    icon: "ri-refund-2-line",
    link: "/reports",
    options: [
      {
        title: "Contas",
        link: "/finances/bills",
      },
      {
        title: "Grupo de contas",
        link: "/finances/bill-groups",
      },
    ],
  },
  {
    title: "Configurações",
    icon: "ri-equalizer-line",
    link: "/settings",
    options: [
      {
        title: "Taxas",
        link: "/settings/taxes",
      },
      {
        title: "Pontos de venda",
        link: "/settings/sales-points",
      },
      {
        title: "Pontos de pedidos",
        link: "/settings/order-locations",
      },
      {
        title: "Setores",
        link: "/settings/order-sectors",
      },
    ],
  },
];
