import TextInput from "../../../../components/inputs/TextInput";
import Tab from "../../../../components/others/Tab";
import { SalesPeriod } from "../../../../types/entities/sales-period";

export type SalesGroupFilterValues = { name: string; status?: string };

type Props = {
  salesPeriod?: SalesPeriod;
  filter: SalesGroupFilterValues;
  onFilterChange: (value: SalesGroupFilterValues) => void;
};

const saleStatusOptions = [
  {
    label: "Todas",
    value: undefined,
  },
  {
    label: "Abertas",
    value: "open",
  },
  {
    label: "Fechadas",
    value: "finished",
  },
];

export default function SalesGroupFilter({ filter, onFilterChange }: Props) {
  return (
    <div className="flex justify-between items-center mb-4 px-4">
      <Tab
        value={filter?.status}
        onChange={(status) => onFilterChange({ ...filter, status })}
        options={saleStatusOptions}
      ></Tab>
      <div className="w-1/3">
        <TextInput
          placeholder="Pesquisar..."
          value={filter.name}
          onChange={(event: any) =>
            onFilterChange({ ...filter, name: event.target.value })
          }
        />
      </div>
    </div>
  );
}
