import { useNavigate } from "react-router-dom";
import ProductForm from "../../../forms/product/ProductForm";
import CommonPage from "../../shared/CommonPage";

export default function ProductFormPage() {
  const navigate = useNavigate();
  return (
    <CommonPage title="Produtos" description="Formulário de produtos">
      <div className="shadow-md p-4 w-full rounded-md  bg-white">
        <ProductForm onSubmit={async () => navigate(-1)} />
      </div>
    </CommonPage>
  );
}
