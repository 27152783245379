import { useNavigate } from "react-router-dom";
import ProviderForm from "../../../forms/product/ProviderForm";
import CommonPage from "../../shared/CommonPage";

export default function ProviderFormPage() {
  const navigate = useNavigate();
  return (
    <CommonPage title="Fornecedores" description="Formulário de fornecedores">
      <div className="shadow-md p-4 w-full rounded-md  bg-white">
        <ProviderForm onSubmit={async () => navigate(-1)} />
      </div>
    </CommonPage>
  );
}
