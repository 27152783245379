import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../components/buttons/Button";
import TextInput from "../../components/inputs/TextInput";
import { userService } from "../../services/user-service";

export type RegisterForm = {
  name: string;
  email: string;
  password: string;
};

export default function RegisterPage() {
  const { register, handleSubmit, formState } = useForm<RegisterForm>({});
  const navigate = useNavigate();

  const onSubmit = async (values: RegisterForm) => {
    try {
      await userService.register(values);
      toast.success("Cadastro realizado com sucesso");
      navigate("/login");
    } catch (error) {
      console.error(error);
      toast.error("Erro ao realizar cadastro");
    }
  };

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full flex flex-col items-center justify-center">
        <h1 className="text-4xl font-bold text-gray-600  mb-14">CADASTRO</h1>
        <div className="mb-3 w-full">
          <TextInput
            placeholder="nome"
            {...register("name", { required: true })}
          ></TextInput>
        </div>
        <div className="mb-3 w-full">
          <TextInput
            placeholder="email"
            {...register("email", { required: true })}
          ></TextInput>
        </div>
        <div className="mb-3 w-full">
          <TextInput
            type="password"
            placeholder="senha"
            autoComplete="current-password"
            {...register("password", { required: true, minLength: 8 })}
          ></TextInput>
        </div>
        <div className="text-full text-center mt-10 flex gap-2 ">
          <Button
            loading={formState.isSubmitting}
            disabled={!formState.isValid}
            type="submit"
          >
            CADASTRAR
          </Button>
        </div>
        <div className="text-center mt-10">
          <Link to="/login">Já tem uma conta?</Link>
        </div>
      </div>
    </form>
  );
}
