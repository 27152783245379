import { classNames } from "primereact/utils";

export type TabOption = {
  label: string;
  value: any;
};

type Props = {
  value: any;
  onChange: (value: any) => void;
  options: TabOption[];
};

export default function Tab({ options, value, onChange }: Props) {
  return (
    <ul className="menu menu-horizontal bg-white border rounded p-0 hover:cursor-pointer">
      {options?.map((option) => (
        <li
          key={option.value ?? 'undef'}
          onClick={() => onChange(option.value)}
          className={classNames(
            "rounded py-3 px-4",
            value === option.value && "bg-primary"
          )}
        >
          {option.label}
        </li>
      ))}
    </ul>
  );
}
