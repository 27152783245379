import FullLoading from "../../../../components/others/FullLoading";
import { SalesGroup } from "../../../../types/entities/sales-group";
import { SalesPeriod } from "../../../../types/entities/sales-period";
import SalesGroupFilter, { SalesGroupFilterValues } from "./SalesGroupFilter";
import SalesGroupListItem from "./SalesGroupListItem";

type Props = {
  salesPeriod?: SalesPeriod;
  loading: boolean;
  salesGroups: SalesGroup[];
  filter: SalesGroupFilterValues;
  valueVisible: boolean;
  onFilterChange: (value: SalesGroupFilterValues) => void;
  onSelect: (salesGroup: SalesGroup) => void;
};

export default function SalesGroupList({
  loading,
  salesPeriod,
  salesGroups,
  filter,
  onFilterChange,
  valueVisible,
  onSelect,
}: Props) {
  return (
    <div className="flex flex-col h-full">
      <SalesGroupFilter
        salesPeriod={salesPeriod}
        filter={filter}
        onFilterChange={onFilterChange}
      />
      <div className="flex-grow relative h-0 overflow-y-auto px-4">
        {salesGroups.length === 0 && (
          <div className="w-full h-full flex items-center justify-center p-6">
            <span>Sem dados</span>
          </div>
        )}
        <ul className="grid gap-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-8 overflow-y pb-2">
          {salesGroups.map((salesGroup) => (
            <SalesGroupListItem
              key={salesGroup.id}
              salesGroup={salesGroup}
              onClick={() => onSelect(salesGroup)}
              valueVisible={valueVisible}
            />
          ))}
        </ul>
        {loading && <FullLoading />}
      </div>
    </div>
  );
}
