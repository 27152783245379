/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Button from "../../../components/buttons/Button";
import FullLoading from "../../../components/others/FullLoading";
import { orderLocationService } from "../../../services/order-location-service";
import { orderSectorService } from "../../../services/order-sector-service";
import { printerService } from "../../../services/printer-service";
import { productCategoryService } from "../../../services/product-category-service";
import { productService } from "../../../services/product-service";
import { salesGroupService } from "../../../services/sales-group-service";
import {
  SaleWithProductOrTax,
  salesService,
} from "../../../services/sales-service";
import { OrderLocation } from "../../../types/entities/order-location";
import {
  ProductSale,
  Sale,
  SaleType,
  TaxSale,
} from "../../../types/entities/sale";
import {
  SalesGroup,
  SalesGroupStatus,
  SalesGroupType,
} from "../../../types/entities/sales-group";
import ExistentSalesGroupFormHeader from "./ExistentSalesGroupFormHeader";
import SaleDialog from "./SaleDialog";
import SalesList from "./SalesList/SalesList";

type Props = {
  salesPeriodId: string;
  id: string;
  onClose: () => any;
  onEdit: (salesGroup: SalesGroup) => any;
};

export default function ExistentSalesGroupForm({
  salesPeriodId,
  id,
  onClose,
  onEdit,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [salesGroup, setSalesGroup] = useState<SalesGroup>();
  const [orderLocation, setOrderLocation] = useState<OrderLocation>();
  const [sales, setSales] = useState<SaleWithProductOrTax[]>([]);

  const saleDialogRef = useRef<any>();

  const load = async () => {
    setLoading(true);
    try {
      const [salesGroup, sales] = await Promise.all([
        salesGroupService.getById(salesPeriodId, id),
        salesService.get(salesPeriodId, id, {}),
      ]);

      if (salesGroup.orderLocationIds && salesGroup.orderLocationIds[0]) {
        const orderLocation = await orderLocationService.getById(
          salesGroup.orderLocationIds[0]
        );
        setOrderLocation(orderLocation);
      }

      setSalesGroup(salesGroup);
      setSales(sales);
    } catch (error) {
      toast.error("Erro ao carregar registro");
    } finally {
      setLoading(false);
    }
  };

  const handleSalePrint = async (values: Sale & ProductSale) => {
    try {
      const product = await productService.getById(values.productId);
      const productCategory = await productCategoryService.getById(
        product.categoryId
      );

      if (productCategory && productCategory.orderSectorId) {
        const orderSector = await orderSectorService.getById(
          productCategory?.orderSectorId
        );

        if (orderSector.shouldPrintOrder) {
          const result = await Swal.fire({
            title: "Impressão de pedido",
            text: `Confirma a impressão do pedido "${values?.name}"?`,
            icon: "question",
            showCancelButton: true,
            cancelButtonText: "Não",
            confirmButtonText: "Sim",
            showLoaderOnConfirm: true,
          });

          if (!result.isConfirmed) {
            return;
          }

          setLoading(true);

          let orderLocation: OrderLocation | undefined;
          if (salesGroup?.orderLocationIds?.[0]) {
            orderLocation = await orderLocationService.getById(
              salesGroup?.orderLocationIds[0]
            );
          }

          await printerService.printOrder({
            name: salesGroup?.name as string,
            type: salesGroup?.type as string,
            description: salesGroup?.description,
            location: orderLocation?.name,
            sector: orderSector.name,
            order: values.name as string,
            quantity: 1,
            orderDescription: values.description as string,
          });
        }
      }
    } catch (error) {
      toast.error("Erro na comunicação com a impressora");
    } finally {
      setLoading(false);
    }
  };

  const onAddSale = async (sale: Sale) => {
    try {
      setLoading(true);

      await salesService.add(salesPeriodId, id, {
        sales: [
          {
            quantity: 1,
            name: sale.name,
            description: sale.description,
            type: sale.type,
            taxId: (sale as TaxSale).taxId,
            productId: (sale as ProductSale).productId,
            composition: (sale as ProductSale).composition,
            cost: sale.cost,
            value: sale.value,
            exemptTaxes: sale.exemptTaxes,
          },
        ],
      });

      await load();

      if (sale.type === SaleType.Product) {
        await handleSalePrint(sale);
      }
    } catch (error) {
      toast.error("Erro ao adicionar pedido");
    } finally {
      setLoading(false);
    }
  };

  const onEditSale = async (sale: Sale) => {
    saleDialogRef.current.show(sale.id);
  };

  const onRemoveSale = async (sale: Sale) => {
    let confirm = true;

    if (sale.quantity === 1) {
      const result = await Swal.fire({
        title: "Exclusão de pedido",
        text: `Confirma a exclusão do pedido "${sale?.name}"?`,
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
        showLoaderOnConfirm: true,
      });
      confirm = result.isConfirmed;
    }

    if (!confirm) {
      return;
    }

    try {
      setLoading(true);
      await salesService.add(salesPeriodId, id, {
        sales: [
          {
            quantity: -1,
            name: sale.name,
            description: sale.description,
            type: sale.type,
            taxId: (sale as TaxSale).taxId,
            productId: (sale as ProductSale).productId,
            composition: (sale as ProductSale).composition,
            value: sale.value,
            cost: sale.cost ?? 0,
            exemptTaxes: sale.exemptTaxes,
          },
        ],
      });
      await load();
    } catch (error) {
      toast.error("Erro ao subtrair pedido");
    } finally {
      setLoading(false);
    }
  };

  const handleSaleBillPrint = async () => {
    if (salesGroup && sales) {
      try {
        await printerService.printSaleBill({
          name: salesGroup?.name,
          type:
            salesGroup?.type === SalesGroupType.Delivery ? "Delivery" : "Local",
          location: orderLocation?.name ?? "",
          description: salesGroup.description,
          sales: sales
            .sort((one, other) => one.type.localeCompare(other.type))
            .map((item) => ({
              name: item.name,
              quantity: item.quantity,
              value: item.totalValue,
              unity: item.value,
              type: item.type,
              exemptTaxes: item.exemptTaxes,
            })),
        });
      } catch (error) {
        toast.error("Erro de comunicação com a impressora");
      }
    }
  };

  const handleClose = async () => {
    await Swal.fire({
      title: salesGroup?.name,
      text: `Confirma o fechamento da venda "${salesGroup?.name}"?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonText: "Sim",
      showLoaderOnConfirm: true,

      preConfirm: async () => {
        try {
          await salesGroupService.finish(salesPeriodId, id);
          await load();
          onClose();
          toast.success(`Venda "${salesGroup?.name}" fechada com sucesso`);
        } catch (error) {
          toast.error(`Erro ao fechar venda "${salesGroup?.name}"`);
        }
      },
    });
  };

  const handleRemove = async () => {
    await Swal.fire({
      title: salesGroup?.name,
      text: `Confirma a exclusão da venda "${salesGroup?.name}"?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonText: "Sim",
      showLoaderOnConfirm: true,

      preConfirm: async () => {
        try {
          await salesGroupService.delete(salesPeriodId, id);
          onClose();
          toast.success(`Venda "${salesGroup?.name}" excluida com sucesso`);
        } catch (error) {
          toast.error(`Erro ao excluir venda "${salesGroup?.name}"`);
        }
      },
    });
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <form className="h-full flex flex-col justify-between">
      <div className="border-b px-6 pb-4">
        <ExistentSalesGroupFormHeader
          salesGroup={salesGroup}
          orderLocation={orderLocation}
          onRemove={handleRemove}
          onEdit={onEdit}
        />
      </div>
      <div className="py-4 flex-grow overflow-y-auto">
        <SalesList
          salesGroup={salesGroup}
          sales={sales}
          onAddSale={onAddSale}
          onRemoveSale={onRemoveSale}
          onEditSale={onEditSale}
          onClose={handleClose}
          onPrint={handleSaleBillPrint}
        />
      </div>
      <div className="w-full">
        <div className="flex justify-end px-6 p-4 border-t">
          <div className="flex gap-2">
            <Button
              disabled={salesGroup?.status === SalesGroupStatus.Finished}
              type="button"
              color="primary"
              icon="ri-add-line"
              onClick={() => saleDialogRef.current.show()}
            >
              Novo pedido
            </Button>
            <Button
              type="button"
              color="success"
              icon="ri-check-line"
              onClick={() => onClose()}
            >
              Concluir
            </Button>
          </div>
        </div>
      </div>
      <SaleDialog
        salesPeriodId={salesPeriodId}
        salesGroupId={id}
        reloadSalesGroup={load}
        ref={saleDialogRef}
      />
      {loading && <FullLoading />}
    </form>
  );
}
