import { useNavigate } from "react-router-dom";
import CompanyTaxForm from "../../../forms/settings/CompanyTaxForm";
import CommonPage from "../../shared/CommonPage";

export default function CompanyTaxFormPage() {
  const navigate = useNavigate();
  return (
    <CommonPage title="Taxas" description="Formulário de taxas">
      <div className="shadow-md p-4 w-full rounded-md  bg-white">
        <CompanyTaxForm onSubmit={async () => navigate(-1)} />
      </div>
    </CommonPage>
  );
}
