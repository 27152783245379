import { currencyFormatter } from "../../../../helper/formatters";
import { Sale } from "../../../../types/entities/sale";

type Props = {
  sale: Sale;
  disabled?: boolean;
  onAddSale?: (sale: Sale) => any;
  onRemoveSale?: (sale: Sale) => any;
  onEditSale?: (sale: Sale) => any;
};

export default function SalesListProductItem({
  sale,
  disabled,
  onRemoveSale,
  onAddSale,
  onEditSale
}: Props) {
  return (
    <li className="py-2 border-b border-dashed">
      <div className="flex gap-4 items-center text-md">
        <div className="flex-grow flex gap-1 min-w-0">
          <span className="whitespace-nowrap text-ellipsis overflow-hidden">
            <span className="">{sale.name}</span>
            {sale.description && (
              <span className="">
                {" - "}
                <span className="text-xs">{sale.description}</span>
              </span>
            )}
          </span>
        </div>
        <div className="">

        <button
            type="button"
            disabled={disabled}
            className=""
            onClick={() => onEditSale?.(sale)}
            >
            <i className="ri-edit-line"></i>
          </button>
            </div>
        <div className="flex items-center">
          <button
            type="button"
            disabled={disabled}
            className="btn btn-circle btn-xs btn-error"
            onClick={() => onRemoveSale?.(sale)}
          >
            <i className="ri-subtract-line"></i>
          </button>
        </div>
        <span className="min-w-[25px] text-center">{sale.quantity}x</span>
        <div className="flex items-center mr-2">
          <button
            type="button"
            disabled={disabled}
            className="btn btn-circle btn-xs btn-success"
            onClick={() => onAddSale?.(sale)}
          >
            <i className="ri-add-line"></i>
          </button>
        </div>
        <span className="min-w-[70px] text-center">
          {currencyFormatter(sale.value)}
        </span>
        <span className="min-w-[100px] text-right font-semibold">
          {currencyFormatter(sale.totalValue)}
        </span>
      </div>
      {}
    </li>
  );
}
