import { Dialog } from "primereact/dialog";
import { classNames } from "primereact/utils";
import { Ref, forwardRef, useImperativeHandle, useMemo, useState } from "react";
import ExistentSalesGroupForm from "../../../../forms/sales-group/ExistentSalesGroupForm/ExistentSalesGroupForm";
import NewSalesGroupForm from "../../../../forms/sales-group/NewSalesGroupForm";
import {
  SalesGroup,
  SalesGroupType,
} from "../../../../types/entities/sales-group";

type Props = {
  salesPeriodId: string;
  reloadSalesPeriod: () => any;
};

const SalesGroupDialog = (
  { salesPeriodId, reloadSalesPeriod }: Props,
  ref: Ref<any>
) => {
  const [mode, setMode] = useState<"form" | "common">();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [salesGroup, setSalesGroup] = useState<SalesGroup>();

  useImperativeHandle(
    ref,
    () => {
      return {
        show: (salesGroup?: SalesGroup) => {
          setMode(salesGroup ? "common" : "form");
          setSalesGroup(salesGroup);
          setDialogOpen(true);
        },
        hide: () => setDialogOpen(false),
      };
    },
    []
  );

  const salesGroupHeader = useMemo(() => {
    if (salesGroup) {
      const salesIcon =
        salesGroup.type === SalesGroupType.Delivery
          ? "ri-box-1-line"
          : "ri-artboard-2-line";

      return (
        <div className="flex gap-2 items-center">
          <i className={classNames(salesIcon, "text-primary text-2xl")} />
          <span className="text-gray-600">{salesGroup.name}</span>
        </div>
      );
    }

    return <span className="text-gray-600">Nova venda</span>;
  }, [salesGroup]);

  const onEdit = (salesGroup: SalesGroup) => {
    setMode("form");
    setSalesGroup(salesGroup);
  };

  return (
    <Dialog
      visible={dialogOpen}
      position={"right"}
      style={{ height: "100vh" }}
      maximizable
      maskClassName="justify-center xl:justify-end "
      className="w-[100vw] xl:w-[50vw] h-full xl:min-h-full xl:m-0 xl:rounded-none"
      onHide={() => {
        reloadSalesPeriod();
        setDialogOpen(false);
      }}
      pt={{
        mask: {
          onClick: () => {
            reloadSalesPeriod();
            setDialogOpen(false);
          },
        },
        root: {
          onClick: (event) => {
            event.stopPropagation();
          },
        },
      }}
      contentClassName="p-0 xl:rounded-none"
      headerClassName="px-6 pt-6 pb-3 xl:rounded-none"
      draggable={false}
      header={salesGroupHeader}
    >
      {mode === "common" && salesGroup ? (
        <ExistentSalesGroupForm
          id={salesGroup.id as string}
          salesPeriodId={salesPeriodId}
          onEdit={onEdit}
          onClose={() => {
            reloadSalesPeriod();
            setDialogOpen(false);
          }}
        />
      ) : (
        <NewSalesGroupForm
          salesPeriodId={salesPeriodId}
          id={salesGroup?.id}
          onClose={() => {
            reloadSalesPeriod();
            setDialogOpen(false);
          }}
          onSubmit={async (salesGorup: SalesGroup) => {
            reloadSalesPeriod();
            setMode("common");
            setSalesGroup(salesGorup);
          }}
        />
      )}
    </Dialog>
  );
};

export default forwardRef(SalesGroupDialog);
