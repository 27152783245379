
type Props = {
  type?: "text" | "password";
  placeholder?: string;
} & Record<string, any>;

export default function NumberInput({
  type = "text",
  placeholder,
  value,
  onChange,
  ...otherProps
}: Props) {
  return (
    <input
      type="number"
      placeholder={placeholder}
      value={value}
      onChange={(event) => onChange(Number(event.target.value))}
      className="input input-bordered w-full bg-white"
      {...otherProps}
    />
  );
}
