import { currencyFormatter } from "../../../../helper/formatters";
import { SaleWithTax } from "../../../../services/sales-service";
import { CompanyTaxType } from "../../../../types/entities/company-tax";
import { Sale } from "../../../../types/entities/sale";

type Props = {
  sale: Sale & SaleWithTax;
  disabled?: boolean;
  onAddSale?: (sale: Sale) => any;
  onRemoveSale?: (sale: Sale) => any;
};

export default function SalesListTaxItem({
  sale,
  disabled,
  onAddSale,
  onRemoveSale,
}: Props) {
  return (
    <li className="py-2 border-b border-dashed">
      <div className="flex gap-4">
        <span className="flex-grow ">{sale.name}</span>
        <div className="flex items-center">
          <button
            type="button"
            disabled={disabled}
            className="btn btn-circle btn-xs btn-error"
            onClick={() => onRemoveSale?.(sale)}
          >
            <i className="ri-subtract-line"></i>
          </button>
        </div>
        <span className="min-w-[25px] text-center">{sale.quantity}x</span>
        <div className="flex items-center mr-2">
          <button
            type="button"
            disabled={disabled || sale.tax.type === CompanyTaxType.Percent}
            className="btn btn-circle btn-xs btn-success"
            onClick={() => onAddSale?.(sale)}
          >
            <i className="ri-add-line"></i>
          </button>
        </div>
        <span className="min-w-[70px] text-center">{currencyFormatter(sale.value)}</span>
        <span className="min-w-[100px] text-right font-semibold">
          {currencyFormatter(sale.totalValue)}
        </span>
      </div>
    </li>
  );
}
