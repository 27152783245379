import { useMemo } from "react";
import Button from "../../../../components/buttons/Button";
import { currencyFormatter } from "../../../../helper/formatters";
import {
  SaleWithProduct,
  SaleWithProductOrTax,
  SaleWithTax,
} from "../../../../services/sales-service";
import { Sale, SaleType } from "../../../../types/entities/sale";
import {
  SalesGroup,
  SalesGroupStatus,
} from "../../../../types/entities/sales-group";
import SalesListProductItem from "./SalesListProductItem";
import SalesListTaxItem from "./SalesListTaxItem";

type Props = {
  salesGroup?: SalesGroup;
  sales: SaleWithProductOrTax[];
  onAddSale?: (sale: Sale) => any;
  onRemoveSale?: (sale: Sale) => any;
  onEditSale?: (sale: Sale) => any;
  onClose: () => any;
  onPrint: () => any;
};

export default function SalesList({
  salesGroup,
  sales,
  onRemoveSale,
  onAddSale,
  onClose,
  onPrint,
  onEditSale,
}: Props) {
  const productSales = useMemo(() => {
    return sales
      .filter((sale) => sale.type === SaleType.Product && !sale.exemptTaxes)
      .sort((one, other) => {
        return (
          new Date(one.createdAt as Date).getTime() -
          new Date(other.createdAt as Date).getTime()
        );
      }) as (Sale & SaleWithProduct)[];
  }, [sales]);

  const taxSales = useMemo(() => {
    return sales
      .filter((sale) => sale.type === SaleType.Tax)
      .sort((one, other) => {
        return (
          new Date(other.createdAt as Date).getTime() -
          new Date(one.createdAt as Date).getTime()
        );
      }) as (Sale & SaleWithTax)[];
  }, [sales]);

  const exemptProductSales = useMemo(() => {
    return sales
      .filter((sale) => sale.type === SaleType.Product && sale.exemptTaxes)
      .sort((one, other) => {
        return (
          new Date(one.createdAt as Date).getTime() -
          new Date(other.createdAt as Date).getTime()
        );
      }) as (Sale & SaleWithProduct)[];
  }, [sales]);

  const productSalesTotal = useMemo(() => {
    return productSales.reduce((acc, curr) => acc + curr.totalValue, 0);
  }, [productSales]);


  const taxSalesTotal = useMemo(() => {
    return taxSales.reduce((acc, curr) => acc + curr.totalValue, 0);
  }, [taxSales]);

  const exemptProductSalesTotal = useMemo(() => {
    return exemptProductSales.reduce((acc, curr) => acc + curr.totalValue, 0);
  }, [exemptProductSales]);

  return (
    <div className="w-full flex flex-col h-full">
      <div className="px-6 pb-2 flex-grow h-[100px] overflow-y-auto">
        <div>
          <div className="flex justify-between items-center">
            <span className="font-semibold">Pedidos</span>
          </div>
          <ul className="mt-2 border-t border-dashed">
            {productSales.map((sale) => (
              <SalesListProductItem
                key={sale.id}
                sale={sale}
                disabled={salesGroup?.status === SalesGroupStatus.Finished}
                onAddSale={onAddSale}
                onRemoveSale={onRemoveSale}
                onEditSale={onEditSale}
              />
            ))}
          </ul>
          <div className="text-right font-semibold mt-2">
            <span>Total Pedidos: {currencyFormatter(productSalesTotal)} </span>
          </div>
        </div>
        {taxSales?.length > 0 && (
          <div className="">
            <div className="flex justify-between items-center">
              <span className="font-semibold">Taxas</span>
            </div>
            <ul className="mt-2 border-t border-dashed">
              {taxSales.map((sale) => (
                <SalesListTaxItem
                  key={sale.id}
                  sale={sale}
                  disabled={salesGroup?.status === SalesGroupStatus.Finished}
                  onAddSale={onAddSale}
                  onRemoveSale={onRemoveSale}
                />
              ))}
            </ul>
            <div className="text-right font-semibold mt-2">
              <span>Total Taxas: {currencyFormatter(taxSalesTotal)} </span>
            </div>
          </div>
        )}
        {exemptProductSales?.length > 0 && (
          <div>
            <div className="flex justify-between items-center">
              <span className="font-semibold">Isentos</span>
            </div>
            <ul className="mt-2 border-t border-dashed">
              {exemptProductSales.map((sale) => (
                <SalesListProductItem
                  key={sale.id}
                  sale={sale}
                  disabled={salesGroup?.status === SalesGroupStatus.Finished}
                  onAddSale={onAddSale}
                  onRemoveSale={onRemoveSale}
                  onEditSale={onEditSale}
                />
              ))}
            </ul>
            <div className="text-right font-semibold mt-2">
              <span>
                Total Isentos: {currencyFormatter(exemptProductSalesTotal)}{" "}
              </span>
            </div>
          </div>
        )}
      </div>
      <div className="pt-2 px-6 flex justify-end border-t text-lg font-semibold">
        <div className="flex flex-col items-end gap-2">
          <span className="">
            Total: {currencyFormatter(taxSalesTotal + productSalesTotal + exemptProductSalesTotal)}
          </span>
          <div className="flex gap-2">
            <Button
              type="button"
              size="sm"
              color="secondary"
              icon="ri-printer-line"
              onClick={onPrint}
              disabled={salesGroup?.value === 0}
            >
              Imprimir
            </Button>

            <Button
              disabled={salesGroup?.status === SalesGroupStatus.Finished}
              type="button"
              color="accent"
              size="sm"
              icon="ri-bill-line"
              onClick={onClose}
            >
              Fechar
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
