import { useNavigate } from "react-router-dom";
import BillForm from "../../../forms/bills/BillForm";
import CommonPage from "../../shared/CommonPage";

export default function BillFormPage() {
  const navigate = useNavigate();
  return (
    <CommonPage
      title="Contas"
      description="Formulário de contas"
    >
      <div className="shadow-md p-4 w-full rounded-md  bg-white">
        <BillForm onSubmit={async () => navigate(-1)} />
      </div>
    </CommonPage>
  );
}
