import { Dialog } from "primereact/dialog";
import { Ref, forwardRef, useImperativeHandle, useState } from "react";
import SaleForm from "../../sale/SaleForm/SaleForm";

type Props = {
  salesPeriodId: string;
  salesGroupId: string;
  reloadSalesGroup: () => any;
};

const SaleDialog = (
  { salesPeriodId, salesGroupId, reloadSalesGroup }: Props,
  ref: Ref<any>
) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [id, setId] = useState<string>();

  useImperativeHandle(
    ref,
    () => {
      return {
        show: (id?: string) => {
          setId(id);
          setDialogOpen(true);
        },
        hide: () => setDialogOpen(false),
      };
    },
    []
  );

  return (
    <Dialog
      visible={dialogOpen}
      position={"left"}
      className="w-[100vw] xl:w-[calc(50vw-30px)]"
      style={{ height: "100vh" }}
      onHide={() => {
        reloadSalesGroup();
        setDialogOpen(false);
      }}
      draggable={false}
      pt={{
        mask: {
          onClick: (event) => {
            reloadSalesGroup();
            setDialogOpen(false);
          },
        },
        root: {
          onClick: (event) => {
            event.stopPropagation();
          },
        },
      }}
      headerClassName="px-6 pt-6 pb-3"
      contentClassName="p-0"
      header={<span className="text-gray-600">Pedido</span>}
    >
      <SaleForm
        salesPeriodId={salesPeriodId}
        salesGroupId={salesGroupId}
        id={id}
        onSubmit={() => {
          reloadSalesGroup();
        }}
        onClose={() => setDialogOpen(false)}
      />
    </Dialog>
  );
};

export default forwardRef(SaleDialog);
