import { SalesPeriod } from "../types/entities/sales-period";
import { axiosInstance } from "./shared/axios-instance";
import { CrudService } from "./shared/crud-service";

export type SalesPeriodDetails = {
  id: string;
  sales: {
    open: number;
    finished: number;
    total: number;
  };
  values: {
    toReceive: number;
    received: number;
    total: number;
  };
  taxesValues?: { name: string; value: number }[];
};

class SalesPeriodService extends CrudService<SalesPeriod> {
  constructor() {
    super("/protected/sales-period");
  }
  async getDetails(id: string): Promise<SalesPeriodDetails> {
    return await axiosInstance.get(`/protected/sales-period/${id}/details`);
  }
  async getCurrent(): Promise<SalesPeriod> {
    return await axiosInstance.get("/protected/sales-period/current");
  }
  async finish(id: string): Promise<SalesPeriod> {
    return await axiosInstance.patch(`/protected/sales-period/${id}/finish`);
  }
}
export const salesPeriodService = new SalesPeriodService();
