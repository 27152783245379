import { ReactElement } from "react";
import { UseFormReturn, useFieldArray } from "react-hook-form";

type RepeaterChildrenProps = {
  withIndexName: (subname: string) => string;
};

type Props = {
  form: UseFormReturn;
  name: string;
  title: string;
  defaultValue?: any;
  children: (props: RepeaterChildrenProps) => ReactElement;
};

export default function FormRepeater({
  name,
  title,
  form,
  defaultValue,
  children,
}: Props) {
  const { fields, append, remove } = useFieldArray({
    name,
    control: form.control,
    shouldUnregister: true,
  });
  return (
    <div className="w-full">
      <h2 className="mb-2 border-b border-gray-200 pb-2">{title}</h2>

      {fields.length === 0 && (
        <div className="p-2 text-center">Sem items..</div>
      )}
      {fields.length > 0 && (
        <ul className="w-full py-2">
          {fields.map((field, index) => (
            <li key={field.id} className=" shadow-md mb-2 rounded-md flex">
              <div className="flex-grow p-4">
                {children({
                  withIndexName: (fieldName: string) =>
                    `${name}.${index}.${fieldName}`,
                })}
              </div>
              <div className="flex flex-col justify-center items-center p-4 border-l border-gray-200">
                <button type="button" onClick={() => remove(index)}>
                  <i className="text-lg ri-delete-bin-fill text-red-400"></i>
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}
      <div className="w-full mt-2 flex justify-center">
        <button
          type="button"
          className="rounded-full bg-primary hover:bg-primary_dark w-10 h-10"
          onClick={() => append(defaultValue ?? {})}
        >
          <i className="text-2xl ri-add-fill text-white"></i>
        </button>
      </div>
    </div>
  );
}
