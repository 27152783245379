import React from "react";

type Props = {
  type?: "text" | "password";
  placeholder?: string;
} & Record<string, any>;

const TextInput = (
  { type = "text", placeholder, ...otherProps }: Props,
  ref: any
) => {
  return (
    <input
      ref={ref}
      type={type}
      placeholder={placeholder}
      className="input input-bordered w-full bg-white"
      {...otherProps}
    />
  );
};

export default React.forwardRef(TextInput);
