export const getCurrentTimeDescription = () => {
  const currentDate = new Date();
  if (currentDate.getHours() <= 12) {
    return "Manhã";
  } else if (currentDate.getHours() <= 18) {
    return "Tarde";
  } else {
    return "Noite";
  }
};
