import { toast } from "react-toastify";
import { useAuthContext } from "../../contexts/auth/AuthContext";
import CompanyForm from "../../forms/company/CompanyForm";
import { companyService } from "../../services/company-service";
import { Company } from "../../types/entities/company";
import FullPage from "../shared/FullPage";

export default function FirstCompanyPage() {
  const auth = useAuthContext();

  const onSubmit = async (values: Partial<Company>) => {
    try {
      await companyService.create(values);
      await auth.loadAuth();
      toast.success("Organização criada com sucesso");
    } catch (error) {
      toast.error("Erro ao criar organização");
    }
  };

  return (
    <FullPage
      title="Seja bem vindo!"
      subtitle="Configuração inicial"
      description="Para iniciar sua utilização é necessário primeiramente criar sua organização. É nela onde todas suas vendas serão realizadas, sendo assim, utilize o mesmo nome do seu comércio."
    >
      <div className="w-[40%]">
        <h3 className="text-center mb-4 text-lg uppercase font-semibold text-gray-800">
          Nova organização
        </h3>
        <CompanyForm onSubmit={onSubmit} />
      </div>
    </FullPage>
  );
}
