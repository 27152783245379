import { WithId } from "./shared/with-id";
import { WithTimestamps } from "./shared/with-timestamps";

export enum ProductOriginType {
  Local = "local",
  Resold = "resold",
}

export type ProductCategory = {
  companyId: string;
  name: string;
  description: string;
  originType: ProductOriginType;
  orderSectorId: string,
  divisible?: boolean;
} & WithId &
  WithTimestamps;
