import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

type Props = {
  row: any;
  onRemove: (row: any) => Promise<any>;
  afterRemove?: () => Promise<void>;
  idField?: string;
  labelField?: string;
  onEdit?: (row: any) => void;
};

export default function CrudActionsWidget({
  row,
  onEdit,
  onRemove,
  afterRemove,
  idField = "id",
  labelField = "name",
}: Props) {
  const navigate = useNavigate();

  const handleEdit = async () => {
    navigate(`edit/${row[idField]}`);
  };

  const handleDelete = async () => {
    await Swal.fire({
      title: "Remoção",
      text: `Confirma a exclusão do item "${row[labelField]}"?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonText: "Sim",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          await onRemove(row);
          await afterRemove?.();
          toast.success("Item removido com sucesso");
        } catch (error) {
          toast.error("Erro ao remover item");
        }
      },
    });
  };

  return (
    <div className="flex justify-center items-center gap-2">
      <button type="button" onClick={() => handleEdit()}>
        <i className="text-lg ri-pencil-fill text-gray-500"></i>
      </button>
      <button type="button" onClick={() => handleDelete()}>
        <i className="text-lg ri-delete-bin-fill text-gray-500"></i>
      </button>
    </div>
  );
}
