import { Column, ColumnEvent } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ReactElement, useEffect, useMemo, useState } from "react";
import FullLoading from "../others/FullLoading";

type CellEditorProps = {
  onChange: (value: any) => void;
  value: any;
  row: any;
};

export type TableInputColumn = {
  field: string;
  label: string;
  fieldsToMerge?: string[];
  formatter?: (value: any, row: any) => string;
  editor?: (props: CellEditorProps) => ReactElement;
  width?: string;
};

type Props = {
  title: string;
  columns: TableInputColumn[];
  options: any[];
  value?: any[];
  loading?: boolean;
  fieldsToMerge?: string[];
  optionRelatedField?: string;
  valueRelatedField?: string;
  onCellEditStart?: () => void;
  onCellEditEnd?: () => void;
  normalizer: (row: any) => any;
  onChange: (value: any) => void;
};

export default function SelectableTableInput({
  title,
  columns,
  options: incommingOptions,
  value,
  onChange,
  loading = false,
  fieldsToMerge,
  normalizer = (value) => value,
  optionRelatedField = "id",
  valueRelatedField = "id",
  onCellEditStart,
  onCellEditEnd,
}: Props) {
  const [options, setOptions] = useState<any[]>(incommingOptions);

  const arrayNormalizer = useMemo(() => {
    return (array: any[]) => array.map((item) => normalizer(item));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selection = useMemo(() => {
    if (value && options) {
      return value
        .map((valueItem) =>
          options.find(
            (option) =>
              option[optionRelatedField] === valueItem[valueRelatedField]
          )
        )
        .filter(Boolean);
    }
    return [];
  }, [value, options, optionRelatedField, valueRelatedField]);

  useEffect(() => {
    if (incommingOptions && value && fieldsToMerge) {
      for (const option of incommingOptions) {
        for (const fieldToMerge of fieldsToMerge) {
          const relatedValue = value.find(
            (valueItem) =>
              option[optionRelatedField] === valueItem[valueRelatedField]
          );
          if (relatedValue) {
            option[fieldToMerge] = relatedValue[fieldToMerge];
          }
        }
      }
    }
    setOptions(incommingOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, options, incommingOptions]);


  const handleCellEdition = (event: ColumnEvent) => {
    const { rowData, field, newValue } = event;
    rowData[field] = newValue;
    onChange(arrayNormalizer(selection));
    onCellEditEnd?.();
  };

  return (
    <div className="w-full relative ">
      <h2 className=" text-gray-700 mb-1 border-gray-200 pb-2">{title}</h2>
      <div className="rounded overflow-hidden">
        <DataTable
          value={options}
          selection={selection}
          selectionMode="checkbox"
          editMode="cell"
          onSelectionChange={(event) => onChange(arrayNormalizer(event.value))}
          emptyMessage="Sem dados..."
        >
          <Column
            key="selection"
            selectionMode="multiple"
            headerStyle={{ width: "3rem" }}
          ></Column>

          {columns.map((column) => (
            <Column
              key={column.field}
              field={column.field}
              header={column.label}
              style={{ width: column.width }}
              onCellEditInit={onCellEditStart}
              onCellEditComplete={handleCellEdition}
              onCellEditCancel={onCellEditEnd}
              editor={
                column.editor
                  ? (options) => (
                      <div className="-m-2">
                        {column.editor?.({
                          onChange: (value) => options.editorCallback?.(value),
                          value: options.value,
                          row: options.rowData,
                        })}
                      </div>
                    )
                  : undefined
              }
              body={(row) =>
                column.formatter
                  ? column.formatter(row[column.field], row)
                  : row[column.field]
              }
            />
          ))}
        </DataTable>
      </div>
      {loading && <FullLoading />}
    </div>
  );
}
