import { Navigate, Route, Routes } from "react-router-dom";
import LoginPage from "../pages/auth/LoginPage";
import RegisterPage from "../pages/auth/RegisterPage";
import VerificationPage from "../pages/auth/VerificationPage";

export default function AuthRouter() {
  return (
    <Routes>
      <Route path="/login" Component={LoginPage} />
      <Route path="/register" Component={RegisterPage} />
      <Route path="/confirmation" Component={VerificationPage} />
      <Route path="*" element={<Navigate replace to="/login" />}></Route>
    </Routes>
  );
}
