import { Dialog } from "primereact/dialog";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCurrentSalesPeriodContext } from "../../contexts/sales/CurrentSalesPeriodContext";
import SalesPeriodForm from "../../forms/sales-period/SalesPeriodForm/SalesPeriodForm";
import { SalesPeriodStatus } from "../../types/entities/sales-period";
import FullLoading from "../others/FullLoading";

export default function SalesPeriodWidget() {
  const { loading, salesPeriod, loadSalesPeriod } =
    useCurrentSalesPeriodContext();
  const [newSalesPeriodDialogOpen, setNewSalesPeriodDialogOpen] =
    useState(false);

  const navigate = useNavigate();

  const handleSalesPeriodClick = () => {
    salesPeriod
      ? navigate(`/sales-periods/${salesPeriod.id}`)
      : setNewSalesPeriodDialogOpen(true);
  };

  const handleOpenSalesPeriod = () => {
    setNewSalesPeriodDialogOpen(true);
  };

  return (
    <>
      <div className="relative w-full py-4 border-b  text-gray-600 ">
        <div
          className="transition-all hover:text-primary hover:cursor-pointer"
          onClick={handleSalesPeriodClick}
        >
          <div className="flex justify-between gap-3 w-full px-6 pb-2 items-center">
            <div className="flex gap-3">
              <i className="ri-store-2-line font-thin" />
              <span className="font-bold">Caixa</span>
            </div>
          </div>
          <div className="w-full px-6">
            <div className="w-full flex justify-between items-center">
              <div>
                <span>
                  Status:{" "}
                  {salesPeriod?.status === SalesPeriodStatus.Open
                    ? "Aberto"
                    : "Fechado"}
                </span>
                {salesPeriod && <div>Nome: {salesPeriod.name ?? "--"}</div>}
              </div>
              {salesPeriod && (
                <button type="button" onClick={handleSalesPeriodClick}>
                  <i className="ri-share-box-line font-thin"></i>
                </button>
              )}
            </div>
          </div>
        </div>
        {salesPeriod?.status !== SalesPeriodStatus.Open && (
          <div className="px-6 mt-4 flex justify-center w-full">
            <button
              type="submit"
              onClick={handleOpenSalesPeriod}
              className="btn btn-sm btn-primary w-full"
            >
              Abrir caixa
            </button>
          </div>
        )}
        {loading && <FullLoading variant="secondary" />}
      </div>
      <Dialog
        header="Novo caixa"
        visible={newSalesPeriodDialogOpen}
        headerClassName="px-6 pt-6 pb-3"
        contentClassName="px-6"
        style={{ width: "50vw" }}
        onHide={() => setNewSalesPeriodDialogOpen(false)}
      >
        <SalesPeriodForm
          onSubmit={async () => {
            setNewSalesPeriodDialogOpen(false);
            loadSalesPeriod();
          }}
          onCreate={(salesPeriod) => {
            navigate(`/sales-periods/${salesPeriod.id}`);
          }}
        ></SalesPeriodForm>
      </Dialog>
    </>
  );
}
