import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../components/buttons/Button";
import TextInput from "../../components/inputs/TextInput";
import { useAuthContext } from "../../contexts/auth/AuthContext";
import { userService } from "../../services/user-service";

export type LoginForm = {
  email: string;
  password: string;
};

export default function LoginPage() {
  const { register, handleSubmit, formState } = useForm<LoginForm>({});
  const { setAuth } = useAuthContext();

  const onSubmit = async (values: LoginForm) => {
    try {
      const result = await userService.login(values);
      localStorage.setItem("authorization", result.token);
      const authorization = await userService.loadAuthorization();
      setAuth(authorization);
    } catch (error) {
      console.error(error);
      toast.error("Credenciais inválidas");
    }
  };

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full flex flex-col items-center justify-center">
        <h1 className="text-4xl font-bold text-gray-600 mb-14">
          LOGIN
        </h1>
        <div className="mb-3 w-full">
          <TextInput
            placeholder="email"
            autoComplete="username"
            {...register("email", { required: true })}
          ></TextInput>
        </div>
        <div className="mb-3 w-full">
          <TextInput
            type="password"
            placeholder="senha"
            autoComplete="current-password"
            {...register("password", { required: true, minLength: 8 })}
          ></TextInput>
        </div>
        <div className="w-full text-right">
          <Link to="/forgot-password">Esqueceu sua senha?</Link>
        </div>
        <div className="text-full text-center mt-10 flex gap-2 ">
          <Button color="primary" disabled={!formState.isValid} type="submit">
            Entrar
          </Button>
        </div>
        <div className="text-center mt-10">
          <Link to="/register">Não tem uma conta?</Link>
        </div>
      </div>
    </form>
  );
}
