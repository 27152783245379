import AuthContainer from "../containers/AuthContainer";
import ProtectedContainer from "../containers/ProtectedContainer";
import { useAuthContext } from "../contexts/auth/AuthContext";
import AuthRouter from "./AuthRouter";
import ProtectedRouter from "./ProtectedRouter";

export default function AppRouter() {
  const { authenticated } = useAuthContext();

  return authenticated ? (
    <ProtectedContainer>
      <ProtectedRouter />
    </ProtectedContainer>
  ) : (
    <AuthContainer>
      <AuthRouter />
    </AuthContainer>
  );
}
