import { Provider } from "../types/entities/provider";
import { CrudService } from "./shared/crud-service";

class ProviderService extends CrudService<Provider> {
  constructor() {
    super("/protected/provider");
  }
}

export const providerService = new ProviderService();
