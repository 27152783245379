/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import { classNames } from "primereact/utils";
import { useEffect, useState } from "react";
import { Resolver, useForm, useWatch } from "react-hook-form";
import { toast } from "react-toastify";
import { array, object, string } from "yup";
import Button from "../../components/buttons/Button";
import SelectInput from "../../components/inputs/SelectInput";
import SelectableTableInput, {
  TableInputColumn,
} from "../../components/inputs/SelectableTableInput";
import TextAreaInput from "../../components/inputs/TextAreaInput";
import TextInput from "../../components/inputs/TextInput";
import FullLoading from "../../components/others/FullLoading";
import { currencyFormatter, percentFormatter } from "../../helper/formatters";
import { companyTaxService } from "../../services/company-tax-service.ts";
import { orderLocationService } from "../../services/order-location-service";
import { salesGroupService } from "../../services/sales-group-service";
import { salesPeriodService } from "../../services/sales-period-service";
import { CompanyTax, CompanyTaxType } from "../../types/entities/company-tax";
import { ProductCategory } from "../../types/entities/product-category";
import { Sale, SaleType } from "../../types/entities/sale";
import { SalesGroup, SalesGroupType } from "../../types/entities/sales-group";
import { SalesPeriod } from "../../types/entities/sales-period";
import FormFooter from "../shared/FormFooter";
import InputContainer from "../shared/InputContainer/InputContainer";

type FormValues = Partial<SalesGroup & { sales: Partial<Sale>[] }>;

type Props = {
  salesPeriodId: string;
  id?: string;
  onSubmit: (salesGroup: SalesGroup) => Promise<void>;
  onClose: () => any;
};

const validationSchema = object({
  name: string().required("Campo obrigatório"),
  type: string().oneOf(Object.values(SalesGroupType)).required(),
  orderLocationIds: array(string())
    .min(1)
    .when(["type"], ([type], schema) => {
      if (type === SalesGroupType.Local) {
        return schema.required();
      } else {
        return schema.optional();
      }
    }),
  description: string().optional(),
});

const taxesColumns: TableInputColumn[] = [
  {
    field: "name",
    label: "Nome",
  },
  {
    field: "value",
    label: "Valor",
    width: "200px",
    formatter: (value: any, row: CompanyTax) => {
      if (row.type === CompanyTaxType.Custom) {
        return currencyFormatter(row.value ?? 0);
      } else {
        return percentFormatter(row.value ?? 0);
      }
    },
  },
];

const salesGroupTypeOptions = [
  {
    value: SalesGroupType.Local,
    label: "Local",
  },
  {
    value: SalesGroupType.Delivery,
    label: "Delivery",
  },
];

export default function NewSalesGroupForm({
  salesPeriodId,
  id,
  onSubmit,
  onClose,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [salesPeriod, setSalesPeriod] = useState<SalesPeriod>();
  const [taxes, setTaxes] = useState<CompanyTax[]>([]);

  const form = useForm<FormValues>({
    resolver: yupResolver(validationSchema) as Resolver<FormValues>,
    mode: "onChange",
    defaultValues: {
      name: "",
      description: "",
      orderLocationIds: [],
      type: SalesGroupType.Local,
      sales: [],
    },
  });

  const loadInitialValues = async () => {
    setLoading(true);
    try {
      const salesPeriod = await salesPeriodService.getById(salesPeriodId);
      setSalesPeriod(salesPeriod);

      if (id) {
        const initialValues = await salesGroupService.getById(
          salesPeriodId,
          id
        );

        delete initialValues.id;
        delete initialValues.createdAt;
        delete initialValues.updatedAt;
        delete initialValues.deletedAt;

        form.reset(initialValues as SalesGroup);
      }
    } catch (error) {
      toast.error("Erro ao carregar registro");
    } finally {
      setLoading(false);
    }
  };

  const submit = async (values: Partial<ProductCategory>) => {
    try {
      if (id) {
        await salesGroupService.update(salesPeriodId, id, values);
        onSubmit({ ...values, id } as SalesGroup);
      } else {
        const created = await salesGroupService.create(salesPeriodId, values);
        onSubmit(created);
      }
    } catch (error) {
      toast.error("Erro ao carregar registro");
    }
  };

  const [orderLocationIds, type] = useWatch({
    name: ["orderLocationIds", "type"],
    control: form.control,
  });

  const handleOrderLocationChange = async (orderLocationIds: string[]) => {
    if (orderLocationIds.length > 0 && orderLocationIds[0]) {
      setLoading(true);
      try {
        const orderLocation = await orderLocationService.getById(
          orderLocationIds[0]
        );
        form.setValue("name", orderLocation.name, { shouldValidate: true });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleTypeChange = async () => {
    if (salesPeriod && type) {
      const companyTaxes = await companyTaxService.get({
        match: {
          id: { __in: salesPeriod.taxes.map((tax) => tax.taxId) },
          salesGroupType: type,
        },
      });
      setTaxes(companyTaxes.items);

      const salesPeriodTaxesByType = salesPeriod.taxes?.filter((periodTax) =>
        companyTaxes.items.some((tax) => tax.id === periodTax.taxId)
      );

      const sales = salesPeriodTaxesByType.map((tax) => ({
        type: SaleType.Tax,
        taxId: tax.taxId,
        value: tax.value,
        name: tax.taxName,
        totalValue: tax.value,
        quantity: 1,
        cost: 0,
      }));
      form.setValue("sales", sales);
      if (type === SalesGroupType.Delivery) {
        form.setValue("orderLocationIds", undefined);
        form.setValue("name", "Delivery", { shouldValidate: true });
      }
    }
  };

  useEffect(() => {
    if (orderLocationIds) {
      handleOrderLocationChange(orderLocationIds);
    }
  }, [orderLocationIds?.[0]]);

  useEffect(() => {
    handleTypeChange();
  }, [type, salesPeriod]);

  useEffect(() => {
    loadInitialValues();
  }, []);

  const onCancel = () => {
    if (id) {
      submit({ ...form.getValues(), id });
    } else {
      onClose();
    }
  };

  return (
    <form className="w-full relative" onSubmit={form.handleSubmit(submit)}>
      <div className="flex flex-col justify-center w-full px-6">
        <div className="grid grid-col-1 w-full">
          <div className="grid grid-cols-4 w-full gap-4">
            <div>
              <InputContainer
                form={form}
                name="type"
                Component={SelectInput}
                label="Tipo"
                options={salesGroupTypeOptions}
              />
            </div>
            {type === SalesGroupType.Local && (
              <div>
                <InputContainer
                  form={form}
                  name="orderLocationIds.[0]"
                  Component={SelectInput}
                  label="Local"
                  optionsFetcher={(query: any) =>
                    orderLocationService.get(query)
                  }
                  itemFetcher={(id: string) => orderLocationService.getById(id)}
                />
              </div>
            )}
            <div
              className={classNames(
                type === SalesGroupType.Local ? "col-span-2" : "col-span-3"
              )}
            >
              <InputContainer
                form={form}
                name="name"
                Component={TextInput}
                label="Nome"
              />
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4">
            <div>
              <InputContainer
                form={form}
                name="description"
                Component={TextAreaInput}
                label="Descrição"
              />
            </div>
          </div>
          {!id && taxes && taxes.length > 0 && (
            <div className="grid grid-cols-1">
              <div>
                <InputContainer
                  form={form}
                  name="sales"
                  Component={SelectableTableInput}
                  title="Taxas"
                  columns={taxesColumns}
                  options={taxes}
                  optionRelatedField="id"
                  valueRelatedField="taxId"
                  fieldsToMerge={["value"]}
                  normalizer={(value: CompanyTax) => ({
                    taxId: value.id,
                    name: value.name,
                    value: value.value,
                    totalValue: value.value,
                    quantity: 1,
                    type: SaleType.Tax,
                    cost: 0,
                  })}
                  loading={loading}
                />
              </div>
            </div>
          )}
        </div>
        <FormFooter>
          {id ? (
            <Button
              type="submit"
              color="success"
              icon="ri-save-line"
              loading={form.formState.isSubmitting}
              disabled={!form.formState.isValid}
            >
              Salvar
            </Button>
          ) : (
            <Button
              type="submit"
              color="success"
              icon="ri-add-line"
              loading={form.formState.isSubmitting}
              disabled={!form.formState.isValid}
            >
              Adicionar
            </Button>
          )}
          <Button type="button" color="normal" onClick={onCancel}>
            Cancelar
          </Button>
        </FormFooter>
      </div>
      {loading && <FullLoading />}
    </form>
  );
}
