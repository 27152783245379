import { OrderSector } from "../types/entities/order-sector";
import { CrudService } from "./shared/crud-service";

class OrderSectorService extends CrudService<OrderSector> {
  constructor() {
    super("/protected/order-sector");
  }
}

export const orderSectorService = new OrderSectorService();
