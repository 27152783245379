/* eslint-disable react-hooks/exhaustive-deps */
import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { PaginatedResponse } from "../../services/shared/crud-service";

type SelectInputOption = {
  value: any;
  label: string;
};

type Props = {
  value?: any;
  onChange?: (value: any) => void;
  placeholder?: string;
  options?: SelectInputOption[];
  optionsFetcher?: (query: any) => Promise<PaginatedResponse>;
  itemFetcher?: (id: string) => Promise<any>;
  queryDefaults?: any;
  valueField?: string;
  labelField?: string;
  searchField?: string;
  revalidate?: any[];
} & Record<string, any>;

const classNamesConfig = {
  container: () => "bg-white rounded-lg px-4 border border-gray-300 ",
  control: () => "py-[0.7rem]",
  menu: () => "bg-white -ml-4 -mt-1 border ",
  option: (state: any) =>
    state.isFocused ? "p-2 bg-primary text-white " : "p-2 bg-white",
  noOptionsMessage: () => "p-4",
  loadingIndicator: () => "text-primary",
};

const stylesConfig = {
  control: (baseStyles: any) => ({ ...baseStyles, cursor: "pointer" }),
  option: (baseStyles: any) => ({ ...baseStyles, cursor: "pointer" }),
};

const noOptionsMessage = () => "Sem opções...";

export default function SelectInput({
  placeholder = "",
  labelField = "name",
  valueField = "id",
  searchField = "name",
  value,
  onChange,
  optionsFetcher,
  itemFetcher,
  queryDefaults,
  options: fixedOptions,
  revalidate = [],
  ...otherProps
}: Props) {
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [loadingItem, setLoadingItem] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [fechedOptions, setFetchedOptions] = useState<SelectInputOption[]>();
  const [selectedOptionReference, setSelectedOptionReference] =
    useState<SelectInputOption>();

  const options = useMemo<SelectInputOption[]>(() => {
    if (fixedOptions) {
      return fixedOptions;
    }
    if (fechedOptions) {
      return fechedOptions;
    }
    return [];
  }, [fixedOptions, fechedOptions]);

  const selectedOption = useMemo(() => {
    if (value) {
      return options?.find((item) => item.value === value);
    }
    return "";
  }, [value, options]);

  const forwardChange = (option: SelectInputOption) => {
    onChange?.(option?.value);
  };

  const fetchData = async (search: string = "") => {
    setLoadingOptions(true);
    try {
      const response = await optionsFetcher?.({
        match: { [searchField]: { __like: search }, ...(queryDefaults ?? {}) },
      });

      const items =
        (Array.isArray(response) ? response : response?.items) ?? [];

      const parsed = items.map((item) => ({
        value: item[valueField],
        label: item[labelField],
      }));
      setFetchedOptions(parsed);
    } catch (error) {
      toast.error("Erro ao carregar opções de seleção");
    } finally {
      setLoadingOptions(false);
    }
  };

  const debouncedFetchData = useMemo(() => {
    return fetchData && debounce(fetchData, 250);
  }, []);

  const loadSelectedItem = async () => {
    setLoadingItem(true);
    try {
      const selectedOptionReference = await itemFetcher?.(value);
      setSelectedOptionReference({
        value: selectedOptionReference[valueField],
        label: selectedOptionReference[labelField],
      });
    } catch (error) {
      toast.error("Erro ao carregar opção selecionada");
    } finally {
      setLoadingItem(false);
    }
  };

  useEffect(() => {
    if (optionsFetcher) {
      fetchData();
    }
  }, revalidate);

  useEffect(() => {
    if (typeof debouncedFetchData === "function") {
      debouncedFetchData(searchValue);
    }
  }, [searchValue]);

  useEffect(() => {
    if (optionsFetcher && value && options) {
      const existentOption = options.find((option) => option.value === value);
      if (!existentOption) {
        if (
          !selectedOptionReference ||
          selectedOptionReference.value !== value
        ) {
          loadSelectedItem();
        }
      }
    }
  }, [value, options]);

  useEffect(() => {
    if (optionsFetcher && options && selectedOptionReference) {
      if (
        options.every(
          (option) => option.value !== selectedOptionReference.value
        )
      ) {
        setFetchedOptions((prev) => [...(prev ?? []), selectedOptionReference]);
      }
    }
  }, [options, selectedOptionReference]);

  return (
    <div className="w-full">
      <Select
        unstyled
        isLoading={loadingItem || loadingOptions}
        loadingMessage={() => "Carregando..."}
        value={selectedOption}
        inputValue={searchValue}
        onInputChange={(value) => setSearchValue(value)}
        onChange={forwardChange as any}
        placeholder={placeholder}
        options={options}
        isClearable
        noOptionsMessage={noOptionsMessage}
        styles={stylesConfig}
        classNames={classNamesConfig}
        {...otherProps}
      />
    </div>
  );
}
