import { useNavigate } from "react-router-dom";
import BillGroupForm from "../../../forms/bills/BillGroupForm";
import CommonPage from "../../shared/CommonPage";

export default function BillGroupFormPage() {
  const navigate = useNavigate();
  return (
    <CommonPage
      title="Grupos de conta"
      description="Formulário de grupos de conta"
    >
      <div className="shadow-md p-4 w-full rounded-md  bg-white">
        <BillGroupForm onSubmit={async () => navigate(-1)} />
      </div>
    </CommonPage>
  );
}
