/* eslint-disable react-hooks/exhaustive-deps */
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Button from "../../../components/buttons/Button";
import FullLoading from "../../../components/others/FullLoading";
import { useCurrentSalesPeriodContext } from "../../../contexts/sales/CurrentSalesPeriodContext";
import { doWithMinimalTime } from "../../../helper/do-with-minimal-time";
import {
  currencyFormatter,
  dateTimeFormatter,
} from "../../../helper/formatters";
import { salesGroupService } from "../../../services/sales-group-service";
import {
  SalesPeriodDetails,
  salesPeriodService,
} from "../../../services/sales-period-service";
import { SalesGroup } from "../../../types/entities/sales-group";
import {
  SalesPeriod,
  SalesPeriodStatus,
} from "../../../types/entities/sales-period";
import CommonPage from "../../shared/CommonPage";
import SalesGroupDialog from "./SalesGroupDialog/SalesGroupDialog";
import { SalesGroupFilterValues } from "./SalesGroupList/SalesGroupFilter";
import SalesGroupList from "./SalesGroupList/SalesGroupList";
import VisbilityButton from "../../../components/others/VisbilityButton";
import VisibilityWrapper from "../../../components/others/VisibilityWrapper";

export default function SalesPeriodPage() {
  const [salesPeriodLoading, setSalesPeriodLoading] = useState(false);
  const [salesGroupsLoading, setSalesGroupsLoading] = useState(false);
  const { loadSalesPeriod: loadCurrentSalesPeriod } =
    useCurrentSalesPeriodContext();
  const [valueVisible, setValueVisible] = useState(false);
  const [taxVisible, setTaxVisible] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  const [salesPeriod, setSalesPeriod] = useState<SalesPeriod>();
  const [salesPeriodDetails, setSalesPeriodDetails] =
    useState<SalesPeriodDetails>();
  const [salesGroups, setSalesGroups] = useState<SalesGroup[]>([]);

  const salesGroupDialogRef = useRef<any>();

  const [salesGroupFilter, setSalesGroupFilter] =
    useState<SalesGroupFilterValues>({
      name: "",
      status: "open",
    });

  const loadSalesPeriod = async () => {
    setSalesPeriodLoading(true);
    try {
      const salesPeriod = await salesPeriodService.getById(params.id as string);
      const salesPeriodDetails = await salesPeriodService.getDetails(
        params.id as string
      );
      setSalesPeriod(salesPeriod);
      setSalesPeriodDetails(salesPeriodDetails);
    } catch (error) {
      toast.error("Erro ao carregar caixa");
      navigate(-1);
    } finally {
      setSalesPeriodLoading(false);
    }
  };

  const loadSalesGroups = async (filter = salesGroupFilter) => {
    setSalesGroupsLoading(true);
    try {
      const response = await doWithMinimalTime(
        salesGroupService.get(params.id as string, {
          match: {
            __or: [
              {
                name: { __like: filter.name },
              },
              {
                description: { __like: filter.name },
              },
            ],
            status: filter.status,
          },
          sort: {
            updatedAt: "desc",
          },
          pagination: {
            limit: 1000,
          },
        }),
        100
      );
      setSalesGroups(response.items);
    } catch (error) {
      toast.error("Erro ao carregar vendas");
      navigate(-1);
    } finally {
      setSalesGroupsLoading(false);
    }
  };

  const afterFirstLoadRef = useRef(false);
  const loadSalesGroupsDebounced = useCallback(
    debounce(loadSalesGroups, 250),
    []
  );

  useEffect(() => {
    loadSalesPeriod();
  }, [params.id]);

  useEffect(() => {
    loadSalesGroups(salesGroupFilter);
  }, [salesGroupFilter.status]);

  useEffect(() => {
    if (salesPeriod) {
      setSalesGroupFilter({
        ...salesGroupFilter,
        status:
          salesPeriod.status === SalesPeriodStatus.Open ? "open" : undefined,
      });
    }
  }, [salesPeriod?.status]);

  useEffect(() => {
    if (afterFirstLoadRef.current) {
      loadSalesGroupsDebounced(salesGroupFilter);
    }
    afterFirstLoadRef.current = true;
  }, [salesGroupFilter.name]);

  const reloadSalesPeriod = async () => {
    await Promise.all([loadSalesPeriod(), loadSalesGroups()]);
  };

  const onSalesGroupSelection = (salesGroup: SalesGroup) => {
    salesGroupDialogRef.current.show(salesGroup);
  };

  const handleClose = async () => {
    await Swal.fire({
      title: salesPeriod?.name,
      text: `Confirma o fechamento do caixa "${salesPeriod?.name}"?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonText: "Sim",
      showLoaderOnConfirm: true,

      preConfirm: async () => {
        try {
          await salesPeriodService.finish(params.id as string);
          await loadSalesPeriod();
          loadCurrentSalesPeriod();
          toast.success(`Caixa "${salesPeriod?.name}" fechada com sucesso`);
        } catch (error) {
          toast.error(`Erro ao fechar caixa "${salesPeriod?.name}"`);
        }
      },
    });
  };

  return (
    <CommonPage title="Caixa" description={salesPeriod?.name ?? ""} noXPadding>
      <div className="flex flex-col h-full">
        <div className=" mb-4 flex justify-between items-center pb-4 border-b px-4">
          <div className="flex gap-4">
            <div className="flex flex-col">
              <div className="flex gap-4 items-center">
                <span>
                  Status:{" "}
                  {salesPeriod?.status === SalesPeriodStatus.Open
                    ? "Aberto"
                    : "Fechado"}
                </span>
                {salesPeriod?.status === SalesPeriodStatus.Open && (
                  <span className="w-2 h-2 rounded-full bg-green-400"></span>
                )}
              </div>
              <span className="text-sm">
                Inicio: {dateTimeFormatter(salesPeriod?.startedAt)}
              </span>
              <span className="text-sm">
                Fim:{" "}
                {salesPeriod?.status === "finished"
                  ? dateTimeFormatter(salesPeriod?.finishedAt ?? new Date())
                  : "-"}
              </span>
            </div>
            <div className="flex flex-col border-l pl-4">
              <span>Vendas</span>
              <span className="text-sm">
                Abertas: {salesPeriodDetails?.sales.open}
              </span>
              <span className="text-sm">
                Fechadas: {salesPeriodDetails?.sales.finished}
              </span>
              <span className="text-sm">
                Totais: {salesPeriodDetails?.sales.total}
              </span>
            </div>
            <div className="flex flex-col border-l pl-4">
              <div className="flex items-center gap-2">
                <span>Valores</span>
                <VisbilityButton
                  visible={valueVisible}
                  onVisibilityChange={setValueVisible}
                />
              </div>
              <span className="text-sm">
                A receber:{" "}
                <VisibilityWrapper visible={valueVisible}>
                  {currencyFormatter(salesPeriodDetails?.values.toReceive ?? 0)}
                </VisibilityWrapper>
              </span>
              <span className="text-sm">
                Recebido:{" "}
                <VisibilityWrapper visible={valueVisible}>
                  {currencyFormatter(salesPeriodDetails?.values.received ?? 0)}
                </VisibilityWrapper>
              </span>
              <span className="text-sm">
                Total:{" "}
                <VisibilityWrapper visible={valueVisible}>
                  {currencyFormatter(salesPeriodDetails?.values.total ?? 0)}
                </VisibilityWrapper>
              </span>
            </div>
            {salesPeriodDetails?.taxesValues &&
              salesPeriodDetails?.taxesValues.length > 0 && (
                <div className="flex flex-col border-l pl-4">
                  <div className="flex items-center gap-2">
                    <span>Taxas</span>
                    <VisbilityButton
                      visible={taxVisible}
                      onVisibilityChange={setTaxVisible}
                    />
                  </div>
                  {salesPeriodDetails?.taxesValues?.map((taxValue) => (
                    <span key={taxValue.name} className="text-sm">
                      {taxValue.name}:{" "}
                      <VisibilityWrapper visible={taxVisible}>
                        {currencyFormatter(taxValue.value)}
                      </VisibilityWrapper>
                    </span>
                  ))}
                </div>
              )}
          </div>
          <div className="flex flex-col gap-2">
            <Button
              size="normal"
              icon="ri-add-line"
              onClick={() => salesGroupDialogRef.current.show()}
              disabled={salesPeriod?.status === SalesPeriodStatus.Finished}
            >
              Nova venda
            </Button>
            <Button
              color="warning"
              size="sm"
              icon="ri-bill-line"
              onClick={handleClose}
              disabled={
                salesPeriodDetails?.sales.finished !==
                  salesPeriodDetails?.sales.total ||
                salesPeriod?.status === SalesPeriodStatus.Finished
              }
            >
              Fechar caixa
            </Button>
          </div>
        </div>
        <div className="flex-grow relative">
          {salesPeriod && (
            <SalesGroupList
              salesPeriod={salesPeriod}
              loading={!salesPeriodLoading && salesGroupsLoading}
              salesGroups={salesGroups}
              filter={salesGroupFilter}
              valueVisible={valueVisible}
              onFilterChange={setSalesGroupFilter}
              onSelect={onSalesGroupSelection}
            />
          )}
        </div>
      </div>
      {salesPeriodLoading && <FullLoading />}
      <SalesGroupDialog
        salesPeriodId={salesPeriod?.id as string}
        ref={salesGroupDialogRef}
        reloadSalesPeriod={reloadSalesPeriod}
      />
    </CommonPage>
  );
}
