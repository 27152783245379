import classNames from "classnames";
import PuffLoader from "react-spinners/PuffLoader";
import {
  ColorStyleGroup,
  ColorStyleType,
  SizeStyleGroup,
  SizeStyleType,
} from "../styles/base-styles";

type Props = {
  color?: ColorStyleType;
  size?: SizeStyleType;
  icon?: string;
  type?: "button" | "submit";
  disabled?: boolean;
  loading?: boolean;
  children: string;
  onClick?: () => void;
};

const colorVariants: ColorStyleGroup = {
  normal: "",
  primary: "btn-primary",
  neutral: "btn-neutral",
  secondary: "btn-secondary",
  accent: "btn-accent",
  success: "btn-success",
  error: "btn-error",
  warning: "btn-warning",
};

const sizeVariants: SizeStyleGroup = {
  normal: "btn",
  sm: "btn-sm",
  lg: "btn-lg",
  xs: "btn-xs",
};

export default function Button({
  color: variant = "primary",
  type = "button",
  size = "normal",
  loading = false,
  disabled = false,
  icon,
  children,
  onClick,
}: Props) {
  return (
    <>
      <button
        className={classNames(
          "btn",
          sizeVariants[size],
          colorVariants[variant]
        )}
        type={type}
        disabled={loading || disabled}
        onClick={onClick}
      >
        {icon && <i className={classNames(icon)}></i>}
        <span>{children}</span>
        {loading && (
          <PuffLoader
            cssOverride={{ display: "inline-block" }}
            size={20}
            color="#81ABFD"
          />
        )}
      </button>
    </>
  );
}
