export const getLastDaysText = (days: number) => {
  let result = [];
  const date = new Date();

  for (let index = 0; index < days; index++) {
    result.push(new Date(date).toLocaleDateString());
    date.setDate(date.getDate() - 1);
  }

  return result.reverse();
};
