import { TablePaginationInfo, TableSortInfo } from "./Table";

export const formatTableSort = (sortInfo?: TableSortInfo) => {
  if (sortInfo) {
    return {
      [sortInfo?.field]: sortInfo?.order === -1 ? "asc" : "desc",
    };
  }
};

export const formatTableSearch = (search: string, field: string) => {
  return {
    [field]: {
      __like: search,
    },
  };
};

export const formatTablePagination = (pagination: TablePaginationInfo) => {
  return {
    limit: pagination.rows,
    skip: pagination.first,
  };
};
