import { useNavigate } from "react-router-dom";
import Button from "../../../components/buttons/Button";
import CrudActionsWidget from "../../../components/others/CrudActionsWidget";
import Table, { TableColumn } from "../../../components/table/Table";
import { doWithMinimalTime } from "../../../helper/do-with-minimal-time";
import { currencyFormatter } from "../../../helper/formatters";
import { productService } from "../../../services/product-service";
import CommonPage from "../../shared/CommonPage";

const columns: TableColumn[] = [
  {
    field: "name",
    label: "Nome",
  },
  {
    field: "price",
    label: "Preço",
    formatter: currencyFormatter,
  },
];

export default function ProductListPage() {
  const navigate = useNavigate();

  return (
    <CommonPage title="Produtos" description="Listagem de produtos">
      <Table
        columns={columns}
        dataFetch={(query) => productService.get(query)}
        actionsColumn={(row, reloadData) => (
          <CrudActionsWidget
            row={row}
            afterRemove={reloadData}
            onRemove={(row) =>
              doWithMinimalTime(productService.delete(row.id), 1000)
            }
          />
        )}
      />
      <div className="my-2 w-full flex justify-end">
        <Button onClick={() => navigate("new")}>Cadastrar produto</Button>
      </div>
    </CommonPage>
  );
}
