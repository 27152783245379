import { Navigate, Route, Routes } from "react-router-dom";
import BillGroupFormPage from "../pages/bill/bill-group/BillGroupFormPage";
import BillGroupListPage from "../pages/bill/bill-group/BillGroupListPage";
import BillFormPage from "../pages/bill/bill/BillFormPage";
import BillListPasge from "../pages/bill/bill/BillListPage";
import HomePage from "../pages/general/HomePage";
import ProductCategoryFormPage from "../pages/product/product-category/ProductCategoryFormPage";
import ProductCategoryListPage from "../pages/product/product-category/ProductCategoryListPage";
import ProductFormPage from "../pages/product/product/ProductFormPage";
import ProductListPage from "../pages/product/product/ProductListPage";
import ProviderFormPage from "../pages/product/provider/ProviderFormPage";
import ProviderListPage from "../pages/product/provider/ProviderListPage";
import SalesPeriodPage from "../pages/sales/SalesPeriodPage/SalesPeriodPage";
import CompanyTaxFormPage from "../pages/settings/company-tax/CompanyTaxFormPage";
import CompanyTaxListPage from "../pages/settings/company-tax/CompanyTaxListPage";
import OrderLocationFormPage from "../pages/settings/order-location/OrderLocationFormPage";
import OrderLocationListPage from "../pages/settings/order-location/OrderLocationListPage";
import OrderSectorFormPage from "../pages/settings/order-sector/OrderSectorFormPage";
import OrderSectorListPage from "../pages/settings/order-sector/OrderSectorListPage";
import SalesPointFormPage from "../pages/settings/sales-point/SalesPointFormPage";
import SalesPointListPage from "../pages/settings/sales-point/SalesPointListPage";

export default function ProtectedRouter() {
  return (
    <Routes>
      <Route path="/home" Component={HomePage} />
      {/* Finance */}
      <Route path="/finances/bills" Component={BillListPasge} />
      <Route path="/finances/bills/new" Component={BillFormPage} />
      <Route path="/finances/bills/edit/:id" Component={BillFormPage} />
      <Route path="/finances/bill-groups" Component={BillGroupListPage} />
      <Route path="/finances/bill-groups/new" Component={BillGroupFormPage} />
      <Route path="/finances/bill-groups/edit/:id" Component={BillGroupFormPage} />
      {/* Products */}
      <Route path="/products/products" Component={ProductListPage} />
      <Route path="/products/products/new" Component={ProductFormPage} />
      <Route path="/products/products/edit/:id" Component={ProductFormPage} />
      <Route path="/products/categories" Component={ProductCategoryListPage} />
      <Route
        path="/products/categories/new"
        Component={ProductCategoryFormPage}
      />
      <Route
        path="/products/categories/edit/:id"
        Component={ProductCategoryFormPage}
      />
      <Route path="/products/providers" Component={ProviderListPage} />
      <Route path="/products/providers/new" Component={ProviderFormPage} />
      <Route path="/products/providers/edit/:id" Component={ProviderFormPage} />
      {/* settings */}
      <Route path="/settings/taxes" Component={CompanyTaxListPage} />
      <Route path="/settings/taxes/new" Component={CompanyTaxFormPage} />
      <Route path="/settings/taxes/edit/:id" Component={CompanyTaxFormPage} />
      <Route path="/settings/sales-points" Component={SalesPointListPage} />
      <Route path="/settings/sales-points/new" Component={SalesPointFormPage} />
      <Route
        path="/settings/sales-points/edit/:id"
        Component={SalesPointFormPage}
      />
      <Route path="/settings/order-sectors" Component={OrderSectorListPage} />
      <Route
        path="/settings/order-sectors/new"
        Component={OrderSectorFormPage}
      />
      <Route
        path="/settings/order-sectors/edit/:id"
        Component={OrderSectorFormPage}
      />
      <Route
        path="/settings/order-locations"
        Component={OrderLocationListPage}
      />
      <Route
        path="/settings/order-locations/new"
        Component={OrderLocationFormPage}
      />
      <Route
        path="/settings/order-locations/edit/:id"
        Component={OrderLocationFormPage}
      />
      {/* sales */}
      <Route path="/sales-periods/:id" Component={SalesPeriodPage}></Route>
      <Route path="*" element={<Navigate replace to="/home" />}></Route>
    </Routes>
  );
}
