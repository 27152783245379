import { SalesGroup } from "../types/entities/sales-group";
import { axiosInstance } from "./shared/axios-instance";
import {
  OkResponse,
  PaginatedResponse
} from "./shared/crud-service";

class SalesPeriodService {
  private baseUrl: string =
    "/protected/sales-period/:salesPeriodId/sales-group";

  async create(
    salesPeriodId: string,
    data: Partial<SalesGroup>
  ): Promise<SalesGroup> {
    return await axiosInstance.post(
      this.baseUrl.replace(":salesPeriodId", salesPeriodId),
      data
    );
  }

  async update(
    salesPeriodId: string,
    id: string,
    data: Partial<SalesGroup>
  ): Promise<OkResponse> {
    return await axiosInstance.patch(this.baseUrl.replace(":salesPeriodId", salesPeriodId) + `/${id}`, data);
  }

  async get(
    salesPeriodId: string,
    query: any
  ): Promise<PaginatedResponse<SalesGroup>> {
    return await axiosInstance.get(this.baseUrl.replace(":salesPeriodId", salesPeriodId), {
      params: {
        _query: JSON.stringify(query),
      },
    });
  }

  async getById(salesPeriodId: string, id: string): Promise<SalesGroup> {
    return await axiosInstance.get(this.baseUrl.replace(":salesPeriodId", salesPeriodId) + `/${id}`);
  }

  async finish(salesPeriodId: string, id: string): Promise<SalesGroup> {
    return await axiosInstance.patch(this.baseUrl.replace(":salesPeriodId", salesPeriodId) + `/${id}/finish`);
  }

  async delete(salesPeriodId: string, id: string): Promise<OkResponse> {
    return await axiosInstance.delete(this.baseUrl.replace(":salesPeriodId", salesPeriodId) + `/${id}`);
  }
}
export const salesGroupService = new SalesPeriodService();
