import prettyMilliseconds from "pretty-ms";

const brlIntl = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

export const currencyFormatter = (data?: number) => {
  return brlIntl.format(data ?? 0);
};

export const percentFormatter = (data?: number) => {
  return `${data ?? 0} %`;
};

export const dateFormatter = (data?: Date) => {
  if (data) {
    const realDate = new Date(data);
    return `${realDate.toLocaleDateString()}`;
  }
};

export const booleanFormatter = (value?: boolean) => {
  if (value) {
    return "Sim";
  } else {
    return "Não";
  }
};

export const dateTimeFormatter = (data?: Date) => {
  if (data) {
    const realDate = new Date(data);
    return `${realDate.toLocaleDateString()} ${realDate.toLocaleTimeString()}`;
  }
};

export const millisecondsFormatter = (data: number) => {
  return prettyMilliseconds(data, { secondsDecimalDigits: 0 });
};
