import { useNavigate } from "react-router-dom";
import OrderSectorForm from "../../../forms/settings/OrderSectorForm";
import CommonPage from "../../shared/CommonPage";

export default function OrderSectorFormPage() {
  const navigate = useNavigate();
  return (
    <CommonPage title="Setores" description="Formulário de setores">
      <div className="shadow-md p-4 w-full rounded-md  bg-white">
        <OrderSectorForm onSubmit={async () => navigate(-1)} />
      </div>
    </CommonPage>
  );
}
