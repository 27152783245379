import { ReactElement, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { salesPeriodService } from "../../services/sales-period-service";
import { SalesPeriod } from "../../types/entities/sales-period";
import { useAuthContext } from "../auth/AuthContext";
import { currentSalesPeriodContext } from "./CurrentSalesPeriodContext";

type Props = { children: ReactElement | ReactElement[] };

const Provider = currentSalesPeriodContext.Provider;

export default function CurrentSalesPeriodContextProvider({ children }: Props) {
  const { authenticated } = useAuthContext();

  const [loading, setLoading] = useState<boolean>(true);
  const [salesPeriod, setSalesPeriod] = useState<SalesPeriod>();


  const loadSalesPeriod = async () => {
    setLoading(true);
    try {
      const salesPeriod = await salesPeriodService.getCurrent();
      setSalesPeriod(salesPeriod);
    } catch (error) {
      toast.error("Erro ao carregar caixa atual");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (authenticated) {
      loadSalesPeriod();
    }
  }, [authenticated]);

  return (
    <Provider
      value={{
        loading,
        salesPeriod,
        loadSalesPeriod,
      }}
    >
      {children}
    </Provider>
  );
}
