import { ReactElement } from "react";
import LaeraSoftwareLogo from "../assets/laerasoftware-logo.svg";
import SellSOLogo from "../assets/logo.svg";

type Props = {
  children?: ReactElement;
};

export default function AuthContainer({ children }: Props) {
  return (
    <div className="h-screen w-screen flex">
      <div className="bg-primary h-full w-5/12 flex flex-col items-center justify-center shadow-md">
        <img src={SellSOLogo}  width="200px" alt="sell so logo"></img>
        <img
          src={LaeraSoftwareLogo}
          className="-m-6"
          alt="laerasoftware logo"
        ></img>
      </div>
      <div className="flex-grow flex flex-col items-center justify-center p-28">
        <div className="flex flex-col justfiy-center items-center w-8/12">
          {children}
        </div>
      </div>
    </div>
  );
}
