import { CompanyTax } from "../types/entities/company-tax";
import { CrudService } from "./shared/crud-service";

class CompanytaxService extends CrudService<CompanyTax> {
  constructor() {
    super("/protected/company-tax");
  }
}

export const companyTaxService = new CompanytaxService();
