import { ReactElement } from "react";
import ProfileWidget from "../components/layout/ProfileWidget";
import Sidebar from "../components/layout/Sidebar";
import { useAuthContext } from "../contexts/auth/AuthContext";
import CurrentSalesPeriodContextProvider from "../contexts/sales/CurrentSalesPeriodContextProvider";
import FirstCompanyPage from "../pages/start/FirstCompanyPage";

type Props = {
  children?: ReactElement;
};

export default function ProtectedContainer({ children }: Props) {
  const { auth } = useAuthContext();

  const hasCompany = !!auth?.companies?.length;
  return hasCompany ? (
    <CurrentSalesPeriodContextProvider>
      <div className="h-screen max-h-screen max-w-screen flex relative overflow-hidden">
        <Sidebar />
        <div className="flex-grow">{children}</div>
        <div className="absolute bottom-1 right-1 text-xs">
          <span className="text-gray-500">Versão: 1.0.0</span>
        </div>
      </div>
      <ProfileWidget />
    </CurrentSalesPeriodContextProvider>
  ) : (
    <FirstCompanyPage></FirstCompanyPage>
  );
}
