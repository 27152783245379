import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use((request) => {
  request.headers["authorization"] = localStorage.getItem("authorization");
  request.headers["company"] = localStorage.getItem("company");
  return request;
});

axiosInstance.interceptors.response.use((response) => response.data);
