import React from "react";
import { PuffLoader } from "react-spinners";
import { ColorStyleGroup, ColorStyleType } from "../styles/base-styles";

type Prop = {
  variant?: ColorStyleType;
};

const styles: ColorStyleGroup = {
  primary: "#00B894",
  secondary: "white",
};

export default function FullLoading({ variant = "primary" }: Prop) {
  return (
    <div className="backdrop-blur-sm absolute top-0 left-0 w-full h-full flex justify-center items-center">
      <PuffLoader color={styles[variant]}></PuffLoader>
    </div>
  );
}
