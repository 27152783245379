import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Resolver, useForm, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { boolean, number, object, string } from "yup";
import Button from "../../components/buttons/Button";
import PercentInput from "../../components/inputs/PercentInput";
import SelectInput from "../../components/inputs/SelectInput";
import TextInput from "../../components/inputs/TextInput";
import ToggleInput from "../../components/inputs/ToggleInput";
import FullLoading from "../../components/others/FullLoading";
import { companyTaxService } from "../../services/company-tax-service.ts";
import { CompanyTax, CompanyTaxType } from "../../types/entities/company-tax";
import { ProductCategory } from "../../types/entities/product-category";
import { SalesGroupType } from "../../types/entities/sales-group";
import FormFooter from "../shared/FormFooter";
import FormSection from "../shared/FormSection";
import InputContainer from "../shared/InputContainer/InputContainer";

type FormValues = Partial<CompanyTax>;

type Props = {
  onSubmit: (values: FormValues) => Promise<void>;
};

const validationSchema = object({
  name: string().required("Campo obrigatório"),
  type: string().required("Campo obrigatório"),
  salesGroupType: string().required("Campo obrigatório"),
  value: number().optional(),
  autoInclude: boolean().optional(),
});

const companyTaxTypeOptions = [
  {
    value: CompanyTaxType.Percent,
    label: "Percentual",
  },
  {
    value: CompanyTaxType.Custom,
    label: "Customizado",
  },
];

const salesGroupTypeOptions = [
  {
    value: SalesGroupType.Local,
    label: "Local",
  },
  {
    value: SalesGroupType.Delivery,
    label: "Delivery",
  },
];

export default function CompanyTaxForm({ onSubmit }: Props) {
  const [loading, setLoading] = useState(false);

  const params = useParams();

  const form = useForm<FormValues>({
    resolver: yupResolver(validationSchema) as Resolver<FormValues>,
    mode: "onTouched",
    defaultValues: {
      name: "",
      type: CompanyTaxType.Percent,
      salesGroupType: SalesGroupType.Local,
      value: 0,
    },
    shouldUnregister: false,
  });

  const loadInitialValues = async () => {
    setLoading(true);
    try {
      const id = params.id;
      if (id) {
        const initialValues = await companyTaxService.getById(id);

        delete initialValues.id;
        delete initialValues.createdAt;
        delete initialValues.updatedAt;
        delete initialValues.deletedAt;

        form.reset(initialValues);
      }
    } catch (error) {
      toast.error("Erro ao carregar registro");
    } finally {
      setLoading(false);
    }
  };

  const submit = async (values: Partial<ProductCategory>) => {
    try {
      const id = params.id;
      if (id) {
        await companyTaxService.update(id, values);
        toast.success("Registro atualizado com sucesso");
      } else {
        await companyTaxService.create(values);
        toast.success("Registro criado com sucesso");
      }
      onSubmit(values);
    } catch (error) {
      toast.error("Erro ao carregar registro");
    }
  };

  useEffect(() => {
    loadInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const selectedType = useWatch({ name: "type", control: form.control });

  return (
    <form className="w-full relative" onSubmit={form.handleSubmit(submit)}>
      <div className="flex flex-col justify-center w-full">
        <FormSection>Geral</FormSection>
        <div className="grid grid-col-1 w-full">
          <div className="grid grid-cols-3 gap-4">
            <div>
              <InputContainer
                form={form}
                name="name"
                Component={TextInput}
                label="Nome"
              />
            </div>
            <div>
              <InputContainer
                form={form}
                name="salesGroupType"
                Component={SelectInput}
                label="Tipo de venda"
                options={salesGroupTypeOptions}
              />
            </div>
            <div>
              <InputContainer
                form={form}
                name="type"
                Component={SelectInput}
                label="Tipo"
                options={companyTaxTypeOptions}
              />
            </div>
          </div>
          {selectedType === CompanyTaxType.Percent && (
            <div className="grid grid-cols-1 gap-4">
              <div className="">
                <InputContainer
                  form={form}
                  name="value"
                  Component={PercentInput}
                  label="Valor"
                />
              </div>
            </div>
          )}
        </div>
        <div className="grid grid-cols-1 gap-4 w-full">
          <div className="">
            <InputContainer
              form={form}
              name="autoInclude"
              Component={ToggleInput}
              label="Incluir automaticamente?"
            />
          </div>
        </div>
        <FormFooter>
          <Button
            type="submit"
            color="success"
            loading={form.formState.isSubmitting}
            disabled={!form.formState.isValid}
          >
            Salvar
          </Button>
        </FormFooter>
      </div>
      {loading && <FullLoading />}
    </form>
  );
}
