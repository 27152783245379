import { classNames } from "primereact/utils";
import { ReactElement } from "react";

type Props = {
  title: string;
  description: string;
  noXPadding?: boolean;
  children: ReactElement | ReactElement[] | any;
};

export default function CommonPage({
  children,
  title,
  description,
  noXPadding,
}: Props) {
  return (
    <div className="w-full max-h-full overflow-y-auto relative box">
      <div className="h-[85px] shadow-sm w-full flex items-center px-4">
        <div>
          <h1 className="font-medium text-3xl text-gray-600">{title}</h1>
          <span>{description}</span>
        </div>
      </div>
      <div
        className={classNames(
          "overflow-y-auto h-[calc(100vh-85px)] py-4",
          noXPadding ? "px-0" : "px-4"
        )}
      >
        {children}
      </div>
    </div>
  );
}
