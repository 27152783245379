import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Resolver, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { array, number, object, string } from "yup";
import Button from "../../../components/buttons/Button";
import CurrencyInput from "../../../components/inputs/CurrencyInput";
import SelectableTableInput, {
  TableInputColumn,
} from "../../../components/inputs/SelectableTableInput";
import TextInput from "../../../components/inputs/TextInput";
import FullLoading from "../../../components/others/FullLoading";
import {
  currencyFormatter,
  percentFormatter,
} from "../../../helper/formatters";
import { companyTaxService } from "../../../services/company-tax-service.ts";
import { salesPeriodService } from "../../../services/sales-period-service";
import {
  CompanyTax,
  CompanyTaxType,
} from "../../../types/entities/company-tax";
import { ProductCategory } from "../../../types/entities/product-category";
import {
  SalesPeriod,
  SalesPeriodTax,
} from "../../../types/entities/sales-period";
import InputContainer from "../../shared/InputContainer/InputContainer";
import { getCurrentTimeDescription } from "./helpers";

type FormValues = Partial<SalesPeriod>;

type Props = {
  onSubmit: (values: FormValues) => Promise<void>;
  onCreate?: (salesPeriod: SalesPeriod) => any;
};

const validationSchema = object({
  name: string().required("Campo obrigatório"),
  description: string().optional(),
  initialMoney: number().required(),
  taxes: array(object({})),
});

const taxesColumns: TableInputColumn[] = [
  {
    field: "name",
    label: "Nome",
  },
  {
    field: "value",
    label: "Valor",
    width: "200px",
    formatter: (value: any, row: CompanyTax) => {
      if (row.type === CompanyTaxType.Custom) {
        return currencyFormatter(row.value ?? 0);
      } else {
        return percentFormatter(row.value ?? 0);
      }
    },
    editor: ({ row, ...inputProps }) =>
      row.type === CompanyTaxType.Custom ? (
        <CurrencyInput {...inputProps} />
      ) : (
        <div>Não editável</div>
      ),
  },
];

export default function SalesPeriodForm({ onSubmit, onCreate }: Props) {
  const [loading, setLoading] = useState(false);
  const [saveBlocked, setSaveBlocked] = useState(false);

  const [taxesLoading, setTaxesLoading] = useState(false);
  const [taxes, setTaxes] = useState<CompanyTax[]>([]);

  const params = useParams();

  const form = useForm<FormValues>({
    resolver: yupResolver(validationSchema) as Resolver<FormValues>,
    mode: "onTouched",
    defaultValues: {
      name: `${getCurrentTimeDescription()} ${new Date().toLocaleDateString()}`,
      description: "",
      initialMoney: 0,
      taxes: []
    },
  });

  const loadInitialValues = async () => {
    setLoading(true);
    try {
      const id = params.id;
      if (id) {
        const initialValues = await salesPeriodService.getById(id);

        delete initialValues.id;
        delete initialValues.createdAt;
        delete initialValues.updatedAt;
        delete initialValues.deletedAt;

        form.reset(initialValues);
      }
    } catch (error) {
      toast.error("Erro ao carregar registro");
    } finally {
      setLoading(false);
    }
  };

  const loadTaxes = async () => {
    setTaxesLoading(true);
    try {
      const { items: taxes } = await companyTaxService.get({
        pagination: { limit: 100 },
      });

      if (!params.id) {
        const autoIncludedTaxes = taxes.filter((item) => item.autoInclude);
        if (autoIncludedTaxes && autoIncludedTaxes.length) {
          form.resetField("taxes", {
            defaultValue: autoIncludedTaxes.map((item) => ({
              taxId: item.id,
              taxName: item.name,
              value: item.value,
            })) as SalesPeriodTax[],
          });
        }
      }

      setTaxes(taxes);
    } catch (error) {
      toast.error("Erro ao carregar taxas");
    } finally {
      setTaxesLoading(false);
    }
  };

  const submit = async (values: Partial<ProductCategory>) => {
    try {
      const id = params.id;
      if (id) {
        await salesPeriodService.update(id, values);
        toast.success("Registro atualizado com sucesso");
      } else {
        const created = await salesPeriodService.create(values);
        toast.success("Registro criado com sucesso");
        onCreate?.(created);
      }
      onSubmit(values);
    } catch (error) {
      toast.error("Erro ao salvar registro");
    }
  };

  useEffect(() => {
    loadInitialValues();
    loadTaxes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  return (
    <form className="w-full relative" onSubmit={form.handleSubmit(submit)}>
      <div className="flex flex-col items-center justify-center w-full">
        <div className="grid grid-col-1 w-full">
          <div className="grid grid-cols-1 gap-4">
            <div>
              <InputContainer
                form={form}
                name="name"
                Component={TextInput}
                label="Nome"
              />
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4">
            <div>
              <InputContainer
                form={form}
                name="description"
                Component={TextInput}
                label="Descrição"
              />
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4">
            <div>
              <InputContainer
                form={form}
                name="initialMoney"
                Component={CurrencyInput}
                label="Dinheiro em caixa"
              />
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4">
            <div>
              <InputContainer
                form={form}
                name="taxes"
                Component={SelectableTableInput}
                title="Taxas"
                columns={taxesColumns}
                options={taxes}
                optionRelatedField="id"
                valueRelatedField="taxId"
                fieldsToMerge={["value"]}
                onCellEditStart={() => setSaveBlocked(true)}
                onCellEditEnd={() => setSaveBlocked(false)}
                normalizer={(value: CompanyTax) => ({
                  taxId: value.id,
                  taxName: value.name,
                  value: value.value,
                })}
                loading={taxesLoading}
              />
            </div>
          </div>
        </div>
        <div className="w-full flex justify-end mt-4">
          <Button
            type="submit"
            loading={form.formState.isSubmitting}
            disabled={!form.formState.isValid || saveBlocked}
          >
            Salvar
          </Button>
        </div>
      </div>
      {loading && <FullLoading />}
    </form>
  );
}
