import { useNavigate } from "react-router-dom";
import OrderLocationForm from "../../../forms/settings/OrderLocationForm";
import CommonPage from "../../shared/CommonPage";

export default function OrderLocationFormPage() {
  const navigate = useNavigate();
  return (
    <CommonPage title="Pontos de pedidos" description="Formulário de pontos de pedidos">
      <div className="shadow-md p-4 w-full rounded-md  bg-white">
        <OrderLocationForm onSubmit={async () => navigate(-1)} />
      </div>
    </CommonPage>
  );
}
