import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PuffLoader from "react-spinners/PuffLoader";
import { toast } from "react-toastify";
import { useAuthContext } from "../../contexts/auth/AuthContext";
import { userService } from "../../services/user-service";

export default function VerificationPage() {
  const [loading, setLoading] = useState(true);
  const [verified, setVerified] = useState(false);
  const { setAuth } = useAuthContext();
  const location = useLocation();
  const navigate = useNavigate();

  const handleConfirmation = async () => {
    try {
      const searchParameters = new URLSearchParams(location.search);
      const token = searchParameters.get("token");
      if (token) {
        const auth = await userService.verify({ token });
        setLoading(false);
        setVerified(true);

        await new Promise((resolve) => setTimeout(resolve, 2000));
        localStorage.setItem("authorization", token);
        setAuth(auth);
        return;
      }
      throw new Error("invalid token");
    } catch (error) {
      setLoading(false);
      toast.error("Token de verificação inválido");
      navigate("/login");
    }
  };

  useEffect(() => {
    handleConfirmation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full flex flex-col items-center justify-center">
      <h1 className="text-4xl font-bold text-gray-600 mb-14">VERIFICAÇÃO</h1>
      {loading && (
        <>
          <div className="mb-5">
            <span> Aguarde enquanto verificamos sua conta... </span>
          </div>
          <PuffLoader color="#81ABFD"></PuffLoader>
        </>
      )}
      {verified && (
        <div className="text-center">
          <span>
            Conta verificada com sucesso! Logo você será redirecionado para o
            dashboard.
          </span>
        </div>
      )}
    </div>
  );
}
