import { useNavigate } from "react-router-dom";
import Button from "../../../components/buttons/Button";
import CrudActionsWidget from "../../../components/others/CrudActionsWidget";
import Table, { TableColumn } from "../../../components/table/Table";
import { doWithMinimalTime } from "../../../helper/do-with-minimal-time";
import { providerService } from "../../../services/provider-service";
import CommonPage from "../../shared/CommonPage";

const columns: TableColumn[] = [
  {
    field: "name",
    label: "Nome",
  },
  {
    field: "email",
    label: "Email",
  },
];

export default function ProviderListPage() {
  const navigate = useNavigate();

  return (
    <CommonPage title="Fornecedores" description="Listagem de fornecedores">
      <Table
        columns={columns}
        dataFetch={(query) => providerService.get(query)}
        actionsColumn={(row, reloadData) => (
          <CrudActionsWidget
            row={row}
            afterRemove={reloadData}
            onRemove={(row) =>
              doWithMinimalTime(providerService.delete(row.id), 1000)
            }
          />
        )}
      />
      <div className="my-2 w-full flex justify-end">
        <Button onClick={() => navigate("new")}>Cadastrar fornecedor</Button>
      </div>
    </CommonPage>
  );
}
