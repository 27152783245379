import { Calendar } from "primereact/calendar";
import { useMemo } from "react";

type Props = {
  type?: "text" | "password";
  placeholder?: string;
} & Record<string, any>;

export default function DateInput({
  type = "text",
  placeholder,
  value,
  onChange,
  ...otherProps
}: Props) {
  const formattedValue = useMemo(() => {
    if (typeof value === "string") {
      return new Date(value);
    } else if (value instanceof Date) {
      return value;
    } else {
      return new Date();
    }
  }, [value]);

  return (
    <Calendar
      placeholder={placeholder}
      value={formattedValue}
      dateFormat="dd/mm/yy"
      onChange={(e) => onChange(e.value)}
      className="input input-bordered w-full bg-white"
      {...otherProps}
    />
  );
}
