type Props = {
  visible: boolean;
  onVisibilityChange: (visibility: boolean) => void;
};

export default function VisbilityButton({
  visible,
  onVisibilityChange,
}: Props) {
  return (
    <button type="button" onClick={() => onVisibilityChange(!visible)}>
      {visible ? (
        <i className="ri-eye-line"></i>
      ) : (
        <i className="ri-eye-off-line"></i>
      )}
    </button>
  );
}
