import React from "react";

type Props = Record<string, any>;

const TextAreaInput = (
  { type = "text", placeholder, ...otherProps }: Props,
  ref: any
) => {
  return (
    <textarea
      ref={ref}
      placeholder={placeholder}
      className="textarea textarea-bordered bg-white"
      {...otherProps}
    ></textarea>
  );
};

export default React.forwardRef(TextAreaInput);
