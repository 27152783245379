import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Resolver, useForm, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { boolean, number, object, string } from "yup";
import Button from "../../components/buttons/Button";
import CurrencyInput from "../../components/inputs/CurrencyInput";
import NumberInput from "../../components/inputs/NumberInput";
import SelectInput from "../../components/inputs/SelectInput";
import TextInput from "../../components/inputs/TextInput";
import ToggleInput from "../../components/inputs/ToggleInput";
import FullLoading from "../../components/others/FullLoading";
import { productCategoryService } from "../../services/product-category-service";
import { productService } from "../../services/product-service";
import { Product } from "../../types/entities/product";
import FormFooter from "../shared/FormFooter";
import FormSection from "../shared/FormSection";
import InputContainer from "../shared/InputContainer/InputContainer";

type FormValues = Partial<Product>;

type Props = {
  onSubmit: (values: FormValues) => Promise<void>;
};

const validationSchema = object({
  name: string().required("Campo obrigatório"),
  categoryId: string().required("Campo obrigatório"),
  description: string().optional(),
  initialQuantity: number().optional(),
  shouldAlertQuantity: boolean().optional(),
  alertQuantity: number().optional(),
  cost: number().optional(),
  price: number().required().required("Campo obrigatório"),
});



export default function ProductForm({ onSubmit }: Props) {
  const [loading, setLoading] = useState(false);

  const params = useParams();

  const form = useForm<FormValues>({
    resolver: yupResolver(validationSchema) as Resolver<FormValues>,
    mode: "onTouched",
    defaultValues: {
      name: "",
      description: "",
      cost: 0,
      price: 0,
      initialQuantity: 1,
      alertQuantity: 1,
      shouldAlertQuantity: true,
    },
  });

  const loadInitialValues = async () => {
    setLoading(true);
    try {
      const id = params.id;
      if (id) {
        const initialValues = await productService.getById(id);

        delete initialValues.id;
        delete initialValues.createdAt;
        delete initialValues.updatedAt;
        delete initialValues.deletedAt;

        form.reset(initialValues);
      }
    } catch (error) {
      toast.error("Erro ao carregar registro");
    } finally {
      setLoading(false);
    }
  };

  const submit = async (values: Partial<Product>) => {
    try {
      const id = params.id;
      if (id) {
        await productService.update(id, values);
        toast.success("Registro atualizado com sucesso");
      } else {
        await productService.create(values);
        toast.success("Registro criado com sucesso");
      }
      onSubmit(values);
    } catch (error) {
      toast.error("Erro ao carregar registro");
    }
  };

  const shouldAlertQuantity = useWatch({
    name: "shouldAlertQuantity",
    control: form.control,
  });

  useEffect(() => {
    loadInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  return (
    <form className="w-full relative" onSubmit={form.handleSubmit(submit)}>
      <div className="flex flex-col justify-center w-full">
        <FormSection>Geral</FormSection>
        <div className="grid grid-col-1 w-full">
          <div className="grid grid-cols-4 gap-4">
            <div className="col-span-3">
              <InputContainer
                form={form}
                name="name"
                Component={TextInput}
                label="Nome"
              />
            </div>
            <div>
              <InputContainer
                form={form}
                name="categoryId"
                Component={SelectInput}
                label="Categoria"
                optionsFetcher={(query: any) =>
                  productCategoryService.get(query)
                }
                itemFetcher={(id: string) => productCategoryService.getById(id)}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4">
            <div>
              <InputContainer
                form={form}
                name="description"
                Component={TextInput}
                label="Descrição"
              />
            </div>
          </div>
          <div className="grid grid-cols-3 gap-4">
            <div>
              <InputContainer
                form={form}
                name="cost"
                Component={CurrencyInput}
                label="Custo"
              />
            </div>
            <div>
              <InputContainer
                form={form}
                name="price"
                Component={CurrencyInput}
                label="Preço"
              />
            </div>
          </div>
          <FormSection>Estoque</FormSection>
          <div className="grid grid-cols-3 gap-4">
            <div>
              <InputContainer
                form={form}
                name="initialQuantity"
                Component={NumberInput}
                label="Quantidade Inicial"
              />
            </div>
            <div>
              <InputContainer
                form={form}
                name="shouldAlertQuantity"
                Component={ToggleInput}
                label="Alertar quantidade?"
              />
            </div>
            {shouldAlertQuantity && (
              <div>
                <InputContainer
                  form={form}
                  name="alertQuantity"
                  Component={NumberInput}
                  label="Quantidade de Alerta"
                />
              </div>
            )}
          </div>
        </div>
        <FormFooter>
          <Button
            type="submit"
            color="success"
            loading={form.formState.isSubmitting}
            disabled={!form.formState.isValid}
          >
            Salvar
          </Button>
        </FormFooter>
      </div>
      {loading && <FullLoading />}
    </form>
  );
}
