import { ReactElement, useEffect, useState } from "react";
import PuffLoader from "react-spinners/PuffLoader";
import { doWithMinimalTime } from "../../helper/do-with-minimal-time";
import { userService } from "../../services/user-service";
import { Company } from "../../types/entities/company";
import { AuthInfo, authContext } from "./AuthContext";
import SellSOLogo from "../../assets/logo.svg";

type Props = { children: ReactElement };

const Provider = authContext.Provider;

export default function AuthContextProvider({ children }: Props) {
  const [auth, setAuth] = useState<AuthInfo>();
  const [company, setCompany] = useState<Company>();
  const [loading, setLoading] = useState(true);

  const loadAuth = async () => {
    try {
      const token = localStorage.getItem("authorization");

      if (token) {
        const auth = await doWithMinimalTime(
          userService.loadAuthorization(),
          1000
        );
        setAuth(auth);
      }
    } catch (error) {
      localStorage.clear();
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    setAuth(undefined);
    setCompany(undefined);
    localStorage.clear();
  }

  const handleCompanySelection = (company: Company) => {
    const companyId = company.id as string;
    localStorage.setItem("company", companyId);
    setCompany(company);
  };

  useEffect(() => {
    loadAuth();
  }, []);

  useEffect(() => {
    if (auth) {
      const companyId = localStorage.getItem("company");
      if (companyId) {
        const existentCompany = auth.companies.find(
          (item) => item.id === companyId
        );

        if (existentCompany) {
          setCompany(existentCompany);
          return;
        }
      }

      if (auth?.companies?.length) {
        const firstCompany = auth.companies[0];
        handleCompanySelection(firstCompany);
      }
    }
  }, [auth]);

  return (
    <Provider
      value={{
        authenticated: !!auth,
        auth,
        setAuth,
        loadAuth,
        logout,
        company,
        setCompany: handleCompanySelection,
      }}
    >
      {loading ? (
        <div className="bg-primary w-full h-screen flex flex-col items-center justify-center">
          <img src={SellSOLogo} className="mb-1" alt="sell so logo"></img>
          <span className="text-white">Carregando informações...</span>
          <PuffLoader className="mt-4" color="white"></PuffLoader>
        </div>
      ) : (
        children
      )}
    </Provider>
  );
}
