import { Bill } from "../types/entities/bill";
import { Company } from "../types/entities/company";
import { SalesGroup } from "../types/entities/sales-group";
import { axiosInstance } from "./shared/axios-instance";

export type CompanyIndicators = {
  products: {
    productsCount: number;
  };
  salesPeriods: {
    salesPeriodsCountLast30Days: number;
  };
  salesGroups: {
    salesGroupsCountToday: number;
    salesGroupsValueToday: number;
    salesGroupsCountLast30Days: number;
    salesGroupsValueLast30Days: number;
    salesGroupsInLast30Days: SalesGroup[];
  };
  sales: {
    salesQuantityCountToday: number;
    salesQuantityCountLast30Days: number;
  };
  bills: {
    costBillsCountToday: number;
    costBillsValueToday: number;
    costBillsCountInLast30Days: number;
    costBillsValueInLast30Days: number;
    costBillsInLast30Days: Bill[];
  };
};

class CompanyService {
  async create(values: Partial<Company>): Promise<Company> {
    return await axiosInstance.post("/protected/company", values);
  }

  async getIndicators(): Promise<CompanyIndicators> {
    return await axiosInstance.get("/protected/company/indicators");
  }
}

export const companyService = new CompanyService();
