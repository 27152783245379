import { ProductCategory } from "../types/entities/product-category";
import { CrudService } from "./shared/crud-service";

class BillGroupService extends CrudService<ProductCategory> {
  constructor() {
    super("/protected/bill-group");
  }
}

export const billGroupService = new BillGroupService();
