import classNames from "classnames";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SidebarOptionConfig } from "./sidebar-list-config";

type Props = SidebarOptionConfig;

export default function SidebarOption({ title, icon, options, link }: Props) {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const handleClick = () => {
    if (options && options.length) {
      setOpen(!open);
    } else if (link) {
      navigate(link);
    }
  };

  return (
    <li
      className={classNames(
        " text-gray-600 cursor-pointer border-b border-b-primary_light last:border-b-0 transition-all",
        !open && "hover:text-primary transition-all"
      )}
      onClick={handleClick}
    >
      <div
        className={classNames(
          "flex py-4 px-6 items-center justify-between",
          open && "text-primary"
        )}
      >
        <div className="flex gap-3 items-center">
          <i className={classNames(icon, "text-md")} />
          <span className="font-semibold">{title}</span>
        </div>
        {options && options.length > 0 && (
          <button
            type="button"
            className={classNames("transition-all", open && "rotate-180")}
          >
            <i className={classNames("ri-arrow-down-s-line")}></i>
          </button>
        )}
      </div>
      {open && (
        <div>
          <ul>
            {options?.map((option) => (
              <li
                key={option.title}
                className="px-8 py-2 font-normal border-b border-b-primary_light last:border-b-0 hover:text-primary transition-all"
                onClick={(event) => {
                  navigate(option.link as string);
                  event.stopPropagation();
                }}
              >
                {option.title}
              </li>
            ))}
          </ul>
        </div>
      )}
    </li>
  );
}
