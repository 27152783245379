import axios from "axios";
import { SaleType } from "../types/entities/sale";

export type PrintableSaleBill = {
  name: string;
  type: string;
  description?: string;
  location?: string;
  sales: {
    name: string;
    quantity: number;
    value: number;
    unity: number;
    type: SaleType,
    exemptTaxes?: boolean
  }[];
};

export type PrintableSaleOrder = {
  name: string;
  type: string;
  sector: string;
  quantity: number;
  description?: string;
  location?: string;
  order: string;
  orderDescription: string;
};

class PrinterService {
  private baseUrl: string = "http://localhost:3070";

  async printSaleBill(saleBill: PrintableSaleBill) {
    return axios.post(`${this.baseUrl}/printer/bill`, saleBill);
  }

  async printOrder(order: PrintableSaleOrder) {
    return axios.post(`${this.baseUrl}/printer/order`, order);
  }
}

export const printerService = new PrinterService();
