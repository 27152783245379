import { Product } from "../types/entities/product";
import { CrudService } from "./shared/crud-service";

class ProductService extends CrudService<Product> {
  constructor() {
    super("/protected/product");
  }
}

export const productService = new ProductService();
