import { NumericFormat } from "react-number-format";

type Props = {
  type?: "text" | "password";
  placeholder?: string;
} & Record<string, any>;

export default function CurrencyInput({
  type = "text",
  placeholder,
  value,
  onChange,
  ...otherProps
}: Props) {
  return (
    <NumericFormat
      placeholder={placeholder}
      decimalScale={2}
      fixedDecimalScale
      decimalSeparator=","
      value={value}
      onValueChange={({ value }) => onChange(Number(value))}
      prefix="R$ "
      thousandSeparator={false}
      className="input input-bordered w-full bg-white"
      {...otherProps}
    />
  );
}
