import { Chart } from "primereact/chart";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import IndicatorCard from "../../components/cards/IndicatorCard";
import { currencyFormatter } from "../../helper/formatters";
import { getLastDaysText } from "../../helper/get-last-days-text";
import {
  CompanyIndicators,
  companyService,
} from "../../services/company-service";
import CommonPage from "../shared/CommonPage";

export default function HomePage() {
  const [indicators, setIndicators] = useState<CompanyIndicators>();

  const load = async () => {
    try {
      const indicators = await companyService.getIndicators();
      setIndicators(indicators);
    } catch (error) {
      toast.error("Erro ao carregar indicadores");
    }
  };

  const chartData = useMemo(() => {
    if (indicators) {
      let labels: string[] = getLastDaysText(30);

      let datasets: any[] = [];

      let salesGroupedBydate: Record<string, number> = {};
      let billsGroupedByDate: Record<string, number> = {};

      const {
        salesGroups: { salesGroupsInLast30Days },
        bills: { costBillsInLast30Days },
      } = indicators;

      for (const label of labels) {
        salesGroupedBydate[label] =
          salesGroupsInLast30Days
            .filter(
              (salesGroup) =>
                new Date(salesGroup.createdAt as Date).toLocaleDateString() ===
                label
            )
            .map((item) => item.value)
            .reduce((acc, curr) => acc + curr, 0) ?? 0;
        billsGroupedByDate[label] =
          costBillsInLast30Days
            .filter(
              (bill) =>
                new Date(bill.effectiveAt as Date).toLocaleDateString() ===
                label
            )
            .map((item) => item.value)
            .reduce((acc, curr) => acc + curr, 0) ?? 0;
      }

      let filteredLabels = [];

      for (const label of labels) {
        if (
          salesGroupedBydate[label] === 0 &&
          billsGroupedByDate[label] === 0
        ) {
          delete salesGroupedBydate[label];
          delete billsGroupedByDate[label];
        } else {
          filteredLabels.push(label);
        }
      }

      datasets.push({
        label: "Vendas",
        lineTension: 0.2,
        data: Object.values(salesGroupedBydate),
        borderColor: "#00b894",
        backgroundColor: "#00b894",
      });
      datasets.push({
        label: "Despesas",
        lineTension: 0.2,
        data: Object.values(billsGroupedByDate),
        borderColor: "#FF8698",
        backgroundColor: "#FF8698",
      });

      return {
        labels: filteredLabels,
        datasets,
      };
    }
  }, [indicators]);

  useEffect(() => {
    load();
  }, []);

  return (
    <CommonPage title="Dashboard" description="Pagina inicial">
      <div className="grid grid-cols-1 w-full">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-4 gap-4">
          {/* SalesGorups Today */}
          <IndicatorCard
            icon="ri-calendar-event-line"
            title="Vendas hoje"
            text={`Valor gerado por ${
              indicators?.salesGroups.salesGroupsCountToday ?? 0
            } vendas`}
            indicator={currencyFormatter(
              indicators?.salesGroups.salesGroupsValueToday
            )}
          />
          <IndicatorCard
            icon="ri-calendar-event-line"
            title="Despesas hoje"
            colorClassName="text-error"
            text={`Valor gerado por ${
              indicators?.bills.costBillsCountToday ?? 0
            } contas`}
            indicator={currencyFormatter(indicators?.bills.costBillsValueToday)}
          />
          <IndicatorCard
            icon="ri-calendar-2-line"
            title="Vendas em 30 dias"
            text={`Valor gerado por ${
              indicators?.salesGroups.salesGroupsCountLast30Days ?? 0
            } vendas`}
            indicator={currencyFormatter(
              indicators?.salesGroups.salesGroupsValueLast30Days
            )}
          />
          <IndicatorCard
            icon="ri-calendar-2-line"
            title="Despesas em 30 dias"
            colorClassName="text-error"
            text={`Valor gerado por ${
              indicators?.bills.costBillsCountInLast30Days ?? 0
            } contas`}
            indicator={currencyFormatter(
              indicators?.bills.costBillsValueInLast30Days
            )}
          />
          <IndicatorCard
            icon="ri-restaurant-line"
            title="Produtos vendidos hoje"
            text={`E ${indicators?.sales.salesQuantityCountLast30Days} vendidos
            nos ultimos 30 dias`}
            indicator={indicators?.sales.salesQuantityCountToday}
          />
          <IndicatorCard
            icon="ri-shopping-cart-2-line"
            title="Produtos registrados"
            text={`Total de produtos registrados`}
            indicator={indicators?.products.productsCount}
          />
        </div>
        <div className="mt-4 shadow rounded bg-white border p-4">
          <div>
            <span className="font-semibold text-gray-600">
              Vendas x Despesas em 30 dias
            </span>
          </div>
          <div>
            <Chart
              type="line"
              height="330px"
              data={chartData ?? {}}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                bezierCurve: false,
              }}
            />
          </div>
        </div>
      </div>
    </CommonPage>
  );
}
