import { dateTimeFormatter } from "../../../helper/formatters";
import { OrderLocation } from "../../../types/entities/order-location";
import {
  SalesGroup,
  SalesGroupStatus,
  SalesGroupType,
} from "../../../types/entities/sales-group";

type Props = {
  salesGroup?: SalesGroup;
  orderLocation?: OrderLocation;
  onRemove: () => any;
  onEdit: (salesGroup: SalesGroup) => any;
};

export default function ExistentSalesGroupFormHeader({
  salesGroup,
  orderLocation,
  onRemove,
  onEdit,
}: Props) {
  return (
    <div className="flex justify-between w-full">
      <div className="flex text-sm">
        <div className="flex flex-col pr-4">
          <div className="flex items-center gap-1">
            <div className="flex gap-2 items-center text-base">
              <span>
                Status:{" "}
                {salesGroup?.status === SalesGroupStatus.Open
                  ? salesGroup?.type === SalesGroupType.Delivery
                    ? "Aberto"
                    : "Aberta"
                  : salesGroup?.type === SalesGroupType.Delivery
                  ? "Fechado"
                  : "Fechada"}
              </span>
              {salesGroup?.status === SalesGroupStatus.Open && (
                <span className="w-2 h-2 rounded-full bg-green-400"></span>
              )}
            </div>
          </div>
          {orderLocation && (
            <span className="">Local: {orderLocation.name}</span>
          )}

          <span className="">
            Descrição: {salesGroup?.description ? salesGroup.description : "-"}
          </span>
        </div>
        <div className="flex flex-col px-4 border-l">
          <span className="">
            Inicio: {dateTimeFormatter(salesGroup?.startedAt)}
          </span>
          <span className="">
            Atualização: {dateTimeFormatter(salesGroup?.updatedAt)}
          </span>
          {salesGroup?.finishedAt && (
            <span className="">
              Fim:{" "}
              {salesGroup?.finishedAt
                ? dateTimeFormatter(salesGroup?.finishedAt)
                : "-"}
            </span>
          )}
        </div>
      </div>
      <div className="flex gap-2">
        <button
          type="button"
          onClick={() => salesGroup && onEdit(salesGroup)}
          className="btn btn-circle btn-sm btn-warning"
          disabled={salesGroup?.status === SalesGroupStatus.Finished}
        >
          <i className="ri-edit-line"></i>
        </button>
        <button
          type="button"
          onClick={onRemove}
          className="btn btn-circle btn-sm btn-error"
        >
          <i className="ri-delete-bin-line"></i>
        </button>
      </div>
    </div>
  );
}
