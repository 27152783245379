import { InputSwitch } from 'primereact/inputswitch';


type Props = {
  type?: "text" | "password";
  placeholder?: string;
} & Record<string, any>;

export default function ToggleInput({
  type = "text",
  placeholder,
  value,
  onChange,
  ...otherProps
}: Props) {
  return (
    <InputSwitch
      checked={value}
      onChange={(event) => onChange(event.value)}
      className='mt-2'
      // className="border border-gray-300 rounded-md p-2 shadow-md outline-none w-full"
      {...otherProps}
    />
  );
}
