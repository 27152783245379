import React, { useContext } from "react";
import { SalesPeriod } from "../../types/entities/sales-period";

export type SalesPeriodContext = {
  loading: boolean;
  salesPeriod?: SalesPeriod;
  loadSalesPeriod: () => Promise<void>;
};

export const currentSalesPeriodContext =
  React.createContext<SalesPeriodContext>({
    loading: false,
  } as SalesPeriodContext);

export const useCurrentSalesPeriodContext = () => {
  return useContext(currentSalesPeriodContext);
};
