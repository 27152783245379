import { CompanyTax } from "../types/entities/company-tax";
import { Product } from "../types/entities/product";
import { Sale, SaleType } from "../types/entities/sale";
import { SalesGroup } from "../types/entities/sales-group";
import { axiosInstance } from "./shared/axios-instance";
import { OkResponse } from "./shared/crud-service";

export type SaleWithTax = {
  type: SaleType.Tax;
  tax: CompanyTax;
};

export type SaleWithProduct = {
  type: SaleType.Product;
  product: Product;
};

export type SaleWithProductOrTax = Sale & (SaleWithProduct | SaleWithTax);

class SalesService {
  private baseUrl: string =
    "/protected/sales-period/:salesPeriodId/sales-group/:salesGroupId/sale";

  async add(
    salesPeriodId: string,
    salesGroupId: string,
    data: Partial<{ sales: Partial<Sale>[] }>
  ): Promise<SalesGroup> {
    return await axiosInstance.post(
      this.baseUrl
        .replace(":salesPeriodId", salesPeriodId)
        .replace(":salesGroupId", salesGroupId),
      data
    );
  }

  async update(
    salesPeriodId: string,
    salesGroupId: string,
    id: string,
    data: Partial<Sale>
  ): Promise<OkResponse> {
    return await axiosInstance.patch(
      this.baseUrl
        .replace(":salesPeriodId", salesPeriodId)
        .replace(":salesGroupId", salesGroupId) + `/${id}`,
      data
    );
  }

  async get(
    salesPeriodId: string,
    salesGroupId: string,
    query: any
  ): Promise<SaleWithProductOrTax[]> {
    return await axiosInstance.get(
      this.baseUrl
        .replace(":salesPeriodId", salesPeriodId)
        .replace(":salesGroupId", salesGroupId),
      {
        params: {
          _query: JSON.stringify(query),
        },
      }
    );
  }

  async getById(
    salesPeriodId: string,
    salesGroupId: string,
    id: string
  ): Promise<Sale> {
    return await axiosInstance.get(
      this.baseUrl
        .replace(":salesPeriodId", salesPeriodId)
        .replace(":salesGroupId", salesGroupId) + `/${id}`
    );
  }

  async delete(
    salesPeriodId: string,
    salesGroupId: string,
    id: string
  ): Promise<OkResponse> {
    return await axiosInstance.delete(
      this.baseUrl
        .replace(":salesPeriodId", salesPeriodId)
        .replace(":salesGroupId", salesGroupId) + `/${id}`
    );
  }
}
export const salesService = new SalesService();
