import { WithId } from "./shared/with-id";
import { WithTimestamps } from "./shared/with-timestamps";

export enum BillType {
  Cost = "cost",
  Income = "income",
}

export type Bill = {
  companyId: string;
  name: string;
  description: string;
  type: BillType;
  groupId: string;
  value: number;
  effective: boolean;
  effectiveAt: Date;
} & WithId &
  WithTimestamps;
