/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Resolver, useFieldArray, useForm, useWatch } from "react-hook-form";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { boolean, number, object, string } from "yup";
import Button from "../../../components/buttons/Button";
import CurrencyInput from "../../../components/inputs/CurrencyInput";
import NumberInput from "../../../components/inputs/NumberInput";
import SelectInput from "../../../components/inputs/SelectInput";
import TextAreaInput from "../../../components/inputs/TextAreaInput";
import TextInput from "../../../components/inputs/TextInput";
import FullLoading from "../../../components/others/FullLoading";
import { orderLocationService } from "../../../services/order-location-service";
import { orderSectorService } from "../../../services/order-sector-service";
import { printerService } from "../../../services/printer-service";
import { productCategoryService } from "../../../services/product-category-service";
import { productService } from "../../../services/product-service";
import { salesGroupService } from "../../../services/sales-group-service";
import { salesService } from "../../../services/sales-service";
import { OrderLocation } from "../../../types/entities/order-location";
import { Product } from "../../../types/entities/product";
import { ProductCategory } from "../../../types/entities/product-category";
import { ProductSale, Sale, SaleType } from "../../../types/entities/sale";
import InputContainer from "../../shared/InputContainer/InputContainer";
import ToggleInput from "../../../components/inputs/ToggleInput";

type FormValues = Partial<Sale & ProductSale>;

type Props = {
  salesPeriodId: string;
  salesGroupId: string;
  id?: string;
  onSubmit: (values: FormValues) => any;
  onClose: () => any;
};

const validationSchema = object({
  name: string().required("Campo obrigatório"),
  description: string().optional(),
  type: string().oneOf(Object.values(SaleType)).required(),
  productId: string().when(["type"], ([type], schema) =>
    type === SaleType.Product ? schema.required() : schema
  ),
  taxId: string().when(["type"], ([type], schema) =>
    type === SaleType.Tax ? schema.required() : schema
  ),
  value: number().min(0).required(),
  quantity: number().min(0).positive().required(),
  cost: number().required(),
  exemptTaxes: boolean().optional(),
});

const initialValues = {
  name: "",
  description: "",
  type: SaleType.Product,
  quantity: 1,
  value: 0,
};

export default function SaleForm({
  salesGroupId,
  salesPeriodId,
  id,
  onSubmit,
  onClose,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState<Product>();
  const [productCategory, setProductCategory] = useState<ProductCategory>();
  const [compositionProducts, setCompositionProducts] = useState<Product[]>([]);

  const form = useForm<FormValues>({
    resolver: yupResolver(validationSchema) as Resolver<FormValues>,
    mode: "onTouched",
    defaultValues: {
      name: "",
      description: "",
      type: SaleType.Product,
      quantity: 1,
      value: 0,
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: "composition",
    control: form.control,
    shouldUnregister: true,
  });

  const loadInitialValues = async () => {
    setLoading(true);
    try {
      if (id) {
        const initialValues = await salesService.getById(
          salesPeriodId,
          salesGroupId,
          id
        );

        delete initialValues.id;
        delete initialValues.createdAt;
        delete initialValues.updatedAt;
        delete initialValues.deletedAt;

        form.reset(initialValues as Sale & ProductSale);
      }
    } catch (error) {
      toast.error("Erro ao carregar registro");
    } finally {
      setLoading(false);
    }
  };

  const handleSalePrint = async (values: Sale) => {
    if (productCategory && productCategory.orderSectorId) {
      try {
        const orderSector = await orderSectorService.getById(
          productCategory?.orderSectorId
        );

        if (orderSector.shouldPrintOrder) {
          const result = await Swal.fire({
            title: "Impressão de pedido",
            text: `Confirma a impressão do pedido "${values?.name}"?`,
            icon: "question",
            showCancelButton: true,
            cancelButtonText: "Não",
            confirmButtonText: "Sim",
            showLoaderOnConfirm: true,
          });

          if (!result.isConfirmed) {
            return;
          }

          setLoading(true);

          const salesGroup = await salesGroupService.getById(
            salesPeriodId,
            salesGroupId
          );

          let orderLocation: OrderLocation | undefined;
          if (salesGroup.orderLocationIds?.[0]) {
            orderLocation = await orderLocationService.getById(
              salesGroup.orderLocationIds[0]
            );
          }

          await printerService.printOrder({
            name: salesGroup.name,
            type: salesGroup.type,
            description: salesGroup.description,
            location: orderLocation?.name,
            sector: orderSector.name,
            quantity: values.quantity as number,
            order: values.name as string,
            orderDescription: values.description as string,
          });
        }
      } catch (error) {
        toast.error("Erro na comunicação com a impressora");
      } finally {
        setLoading(false);
      }
    }
  };

  const submit = async (values: Partial<ProductCategory>) => {
    try {
      if (id) {
        await salesService.update(salesPeriodId, salesGroupId, id, values);
        toast.success("Pedido atualizado com sucesso", {
          position: "top-left",
        });
      } else {
        await salesService.add(salesPeriodId, salesGroupId, {
          sales: [values],
        });
        toast.success("Pedido adicionado com sucesso", {
          position: "top-left",
        });
      }
      handleSalePrint(values as Sale);
      onSubmit(values);
      if (id) {
        onClose();
      }
      form.reset(initialValues as Sale & ProductSale);
    } catch (error) {
      toast.error("Erro ao concluir pedido");
    }
  };

  useEffect(() => {
    loadInitialValues();
  }, [id]);

  const [productId, composition] = useWatch({
    name: ["productId", "composition"],
    control: form.control,
  });

  const [quantity, value] = useWatch({
    name: ["quantity", "value"],
    control: form.control,
  });

  const handleProductsChange = async () => {
    try {
      if (productId) {
        setLoading(true);
        const product = await productService.getById(productId as string);
        const productCategory = await productCategoryService.getById(
          product.categoryId
        );
        setProduct(product);
        setProductCategory(productCategory);
      } else {
        setProduct(undefined);
        setProductCategory(undefined);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCompositionChange = async () => {
    try {
      if (composition && composition.length) {
        setLoading(true);
        const compositionProductIds =
          composition?.map((item) => item.productId) ?? [];
        const compositionProducts = await productService.get({
          match: { id: { __in: compositionProductIds } },
        });
        setCompositionProducts(compositionProducts.items);
      } else {
        setCompositionProducts([]);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleOrderInfo = async () => {
    if (productId && product && productCategory) {
      if (
        productCategory.divisible &&
        composition &&
        composition.length &&
        compositionProducts &&
        compositionProducts.length
      ) {
        const orderedComposition = composition
          .map((compositionItem) =>
            compositionProducts.find(
              (product) => product.id === compositionItem.productId
            )
          )
          .filter(Boolean);

        const compositionName = orderedComposition
          .map((item) => `Meia ${item?.name}`)
          .join(" / ");

        const greatestPriceProduct = orderedComposition.sort(
          (one, other) => (other?.price ?? 0) - (one?.price ?? 0)
        )[0];

        form.setValue("cost", greatestPriceProduct?.cost ?? 0);
        form.setValue("value", greatestPriceProduct?.price ?? 0);
        form.setValue("name", compositionName);
      } else {
        form.setValue("cost", product.cost);
        form.setValue("value", product.price);
        form.setValue("name", product.name);
      }
    }
  };

  useEffect(() => {
    form.setValue("totalValue", (quantity ?? 1) * (value ?? 0));
  }, [quantity, value]);

  useEffect(() => {
    handleOrderInfo();
  }, [productId, product, productCategory, composition, compositionProducts]);

  useEffect(() => {
    handleProductsChange();
  }, [productId]);

  useEffect(() => {
    handleCompositionChange();
  }, [composition]);

  useEffect(() => {
    if (!productCategory?.divisible) {
      form.setValue("composition", undefined);
    } else if (!composition) {
      form.setValue("composition", []);
    }
  }, [productCategory]);

  return (
    <form
      className="w-full relative flex h-full flex-col justify-between"
      onSubmit={form.handleSubmit(submit)}
    >
      <div className="flex flex-col w-full px-6 pb-6 flex-grow overflow-y-auto">
        {(!composition || composition.length === 0) && (
          <div className="flex gap-4 w-full items-center ">
            <div className="flex-grow">
              <InputContainer
                form={form}
                name="productId"
                Component={SelectInput}
                label="Produto"
                optionsFetcher={(query: any) => productService.get(query)}
                itemFetcher={(id: string) => productService.getById(id)}
              />
            </div>
            {productCategory?.divisible && (
              <button
                type="button"
                onClick={() =>
                  append([
                    { percent: 50, productId: productId as string },
                    { percent: 50, productId: "" },
                  ])
                }
                className="btn btn-sm btn-circle btn-success mt-3"
              >
                <i className="ri-add-line text-lg"></i>
              </button>
            )}
          </div>
        )}
        {productCategory?.divisible && fields.length > 0 && (
          <div>
            <div className="mb-2">
              <span className=" font-semibold">Composição: </span>
            </div>
            <div className="ml-2">
              {fields.map((field, index) => (
                <div key={field.id} className="flex gap-4 w-full items-center">
                  <div className="flex-grow">
                    <InputContainer
                      form={form}
                      name={`composition.${index}.productId`}
                      Component={SelectInput}
                      label={`Produto ${index + 1}`}
                      queryDefaults={{ categoryId: productCategory?.id }}
                      optionsFetcher={(query: any) => productService.get(query)}
                      itemFetcher={(id: string) => productService.getById(id)}
                    />
                  </div>
                  {index === 0 ? (
                    <button
                      type="button"
                      onClick={() => append({ percent: 50, productId: "" })}
                      className="btn btn-sm btn-circle btn-success mt-3"
                    >
                      <i className="ri-add-line text-lg"></i>
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => remove(index)}
                      className="btn btn-sm btn-circle btn-error mt-3"
                    >
                      <i className="ri-subtract-line text-lg"></i>
                    </button>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
        <div className="grid grid-cols-4 gap-4">
          <div className="col-span-2">
            <InputContainer
              form={form}
              name="name"
              Component={TextInput}
              label="Pedido"
              readOnly
            />
          </div>
          <div className="">
            <InputContainer
              form={form}
              name="quantity"
              Component={NumberInput}
              label="Quantidade"
            />
          </div>
          <div className="">
            <InputContainer
              form={form}
              name="value"
              Component={CurrencyInput}
              label="Valor"
            />
          </div>
        </div>
        <div className="grid grid-cols-1">
          <InputContainer
            form={form}
            name="description"
            Component={TextAreaInput}
            label="Descrição"
          />
        </div>
        <div className="grid grid-cols-1">
          <InputContainer
            form={form}
            name="exemptTaxes"
            Component={ToggleInput}
            label="Isentar taxas?"
          />
        </div>
      </div>
      <div className="px-6 p-4">
        <div className="flex justify-end border-t pt-4 pb-2">
          <div className="w-1/3">
            <InputContainer
              form={form}
              name="totalValue"
              Component={CurrencyInput}
              label="Valor total"
              readOnly
            />
          </div>
        </div>
        <div className="flex justify-end border-t pt-4 gap-2">
          {id ? (
            <Button type="submit" color="success" icon="ri-save-line">
              Salvar
            </Button>
          ) : (
            <Button type="submit" color="success" icon="ri-add-line">
              Adicionar
            </Button>
          )}
          <Button type="button" color="normal" onClick={onClose}>
            Cancelar
          </Button>
        </div>
      </div>

      {loading && <FullLoading />}
    </form>
  );
}
