import { SalesGroupType } from "./sales-group";
import { WithId } from "./shared/with-id";
import { WithTimestamps } from "./shared/with-timestamps";

export enum CompanyTaxType {
  Percent = "percent",
  Custom = "custom",
}

export type CompanyTax = {
  companyId: string;
  name: string;
  type: CompanyTaxType;
  salesGroupType: SalesGroupType;
  autoInclude?: boolean;
  value: number;
} & WithId &
  WithTimestamps;
