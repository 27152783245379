import { classNames } from "primereact/utils";
import { currencyFormatter } from "../../../../helper/formatters";
import {
  SalesGroup,
  SalesGroupType,
} from "../../../../types/entities/sales-group";
import VisibilityWrapper from "../../../../components/others/VisibilityWrapper";

type Props = {
  salesGroup: SalesGroup;
  valueVisible: boolean,
  onClick?: () => void;
};

export default function SalesGroupListItem({ salesGroup, valueVisible, onClick }: Props) {
  return (
    <li
      onClick={onClick}
      className="p-4 w-full rounded-md border border-gray-300 shadow-md flex justify-center items-center cursor-pointer hover:bg-green-100 transition-all"
    >
      <div className="flex flex-col justify-center items-center gap-1 overflow-hidden">
        <i
          className={classNames(
            "text-primary text-5xl",
            salesGroup.type === SalesGroupType.Delivery
              ? "ri-box-1-line"
              : "ri-artboard-2-line"
          )}
        ></i>
        <div className="w-full overflow-x-hidden flex justify-center">
          <span className="whitespace-nowrap text-ellipsis overflow-hidden">
            {salesGroup.name}
          </span>
        </div>
        <div className="w-full overflow-x-hidden flex justify-center">
          <span className="whitespace-nowrap text-ellipsis overflow-hidden text-xs">
            {salesGroup?.description || "-"}
          </span>
        </div>
        <span className="text-xs">
          <VisibilityWrapper visible={valueVisible}>

          {currencyFormatter(salesGroup.value)}
          </VisibilityWrapper>
          </span>
      </div>
    </li>
  );
}
