import { useNavigate } from "react-router-dom";
import SalesPointForm from "../../../forms/settings/SalesPointForm";
import CommonPage from "../../shared/CommonPage";

export default function SalesPointFormPage() {
  const navigate = useNavigate();
  return (
    <CommonPage title="Pontos de venda" description="Formulário de pontos de venda">
      <div className="shadow-md p-4 w-full rounded-md  bg-white">
        <SalesPointForm onSubmit={async () => navigate(-1)} />
      </div>
    </CommonPage>
  );
}
