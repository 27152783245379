import { useNavigate } from "react-router-dom";
import Button from "../../../components/buttons/Button";
import CrudActionsWidget from "../../../components/others/CrudActionsWidget";
import Table, { TableColumn } from "../../../components/table/Table";
import { doWithMinimalTime } from "../../../helper/do-with-minimal-time";
import { orderLocationService } from "../../../services/order-location-service";
import CommonPage from "../../shared/CommonPage";

const columns: TableColumn[] = [
  {
    field: "name",
    label: "Nome",
  },
];

export default function OrderLocationListPage() {
  const navigate = useNavigate();

  return (
    <CommonPage
      title="Pontos de pedidos"
      description="Listagem de pontos de pedidos"
    >
      <Table
        columns={columns}
        dataFetch={(query) => orderLocationService.get(query)}
        actionsColumn={(row, reloadData) => (
          <CrudActionsWidget
            row={row}
            afterRemove={reloadData}
            onRemove={(row) =>
              doWithMinimalTime(orderLocationService.delete(row.id), 1000)
            }
          />
        )}
      />
      <div className="my-2 w-full flex justify-end">
        <Button onClick={() => navigate("new")}>Cadastrar ponto de pedido</Button>
      </div>
    </CommonPage>
  );
}
