import { useEffect, useRef, useState } from "react";
import { useAuthContext } from "../../contexts/auth/AuthContext";

export default function ProfileWidget() {
  const [open, setOpen] = useState(false);
  const ref = useRef<any>(null);
  const { auth, logout } = useAuthContext();

  useEffect(() => {
    const handler = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <div
      ref={ref}
      className="absolute top-[20px] right-6 flex flex-col cursor-pointer"
      onClick={() => setOpen(!open)}
    >
      <div className="flex items-center gap-2">
        <span className="">{auth?.name}</span>
        <div className="rounded-full border-2 border-primary w-10 h-10 flex flex-col items-center justify-center">
          <i className="ri-user-3-line text-3xl text-primary"></i>
        </div>
      </div>
      {open && (
        <ul className="w-full bg-white shadow-md mt-2 border border-primary">
          <li
            className="p-2 hover:bg-primary hover:text-white rounded-b-sm"
            onClick={logout}
          >
            <span>Sair</span>
          </li>
        </ul>
      )}
    </div>
  );
}
