import { ProductCategory } from "../types/entities/product-category";
import { CrudService } from "./shared/crud-service";

class ProductCategoryService extends CrudService<ProductCategory> {
  constructor() {
    super("/protected/product-category");
  }
}

export const productCategoryService = new ProductCategoryService();
