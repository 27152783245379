import { ReactElement } from "react";

type Props = {
  title: string;
  subtitle: string;
  description: string;
  children: ReactElement | ReactElement[];
};

export default function FullPage({
  title,
  description,
  subtitle,
  children,
}: Props) {
  return (
    <div className="h-screen w-screen flex">
      <div className="w-7/12 flex flex-col justify-center items-center">
     {children}
      </div>
      <div className="w-5/12 bg-primary flex flex-col justify-center items-center p-20 text-white">
        <h1 className="text-3xl mb-4 font-bold">{title}</h1>
        <h2 className="text-xl mb-6 font-bold">{subtitle}</h2>
        <span className="text-center font-semibold">{description}</span>
      </div>
    </div>
  );
}
